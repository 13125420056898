window.tns = (function () {
    // keys
    if (!Object.keys) {
        Object.keys = function (object) {
            var keys = [];
            for (var name in object) {
                if (Object.prototype.hasOwnProperty.call(object, name)) {
                    keys.push(name);
                }
            }
            return keys;
        };
    }

    // ChildNode.remove
    (function () {
        "use strict";

        if (!("remove" in Element.prototype)) {
            Element.prototype.remove = function () {
                if (this.parentNode) {
                    this.parentNode.removeChild(this);
                }
            };
        }
    })();

    var win = window;

    var raf = win.requestAnimationFrame
        || win.webkitRequestAnimationFrame
        || win.mozRequestAnimationFrame
        || win.msRequestAnimationFrame
        || function (cb) { return setTimeout(cb, 16); };

    var win$1 = window;

    var caf = win$1.cancelAnimationFrame
        || win$1.mozCancelAnimationFrame
        || function (id) { clearTimeout(id); };

    function extend() {
        var obj, name, copy,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length;

        for (; i < length; i++) {
            if ((obj = arguments[i]) !== null) {
                for (name in obj) {
                    copy = obj[name];

                    if (target === copy) {
                        continue;
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
        }
        return target;
    }

    function checkStorageValue(value) {
        return ['true', 'false'].indexOf(value) >= 0 ? JSON.parse(value) : value;
    }

    function setLocalStorage(storage, key, value, access) {
        if (access) { storage.setItem(key, value); }
        return value;
    }

    function getSlideId() {
        var id = window.tnsId;
        window.tnsId = !id ? 1 : id + 1;

        return 'tns' + window.tnsId;
    }

    function getBody() {
        var doc = document,
            body = doc.body;

        if (!body) {
            body = doc.createElement('body');
            body.fake = true;
        }

        return body;
    }

    var docElement = document.documentElement;

    function setFakeBody(body) {
        var docOverflow = '';
        if (body.fake) {
            docOverflow = docElement.style.overflow;
            //avoid crashing IE8, if background image is used
            body.style.background = '';
            //Safari 5.13/5.1.4 OSX stops loading if ::-webkit-scrollbar is used and scrollbars are visible
            body.style.overflow = docElement.style.overflow = 'hidden';
            docElement.appendChild(body);
        }

        return docOverflow;
    }

    function resetFakeBody(body, docOverflow) {
        if (body.fake) {
            body.remove();
            docElement.style.overflow = docOverflow;
            // Trigger layout so kinetic scrolling isn't disabled in iOS6+
            // eslint-disable-next-line
            docElement.offsetHeight;
        }
    }

    // get css-calc 

    function calc() {
        var doc = document,
            body = getBody(),
            docOverflow = setFakeBody(body),
            div = doc.createElement('div'),
            result = false;

        body.appendChild(div);
        try {
            var str = '(10px * 10)',
                vals = ['calc' + str, '-moz-calc' + str, '-webkit-calc' + str],
                val;
            for (var i = 0; i < 3; i++) {
                val = vals[i];
                div.style.width = val;
                if (div.offsetWidth === 100) {
                    result = val.replace(str, '');
                    break;
                }
            }
        } catch (e) { }

        body.fake ? resetFakeBody(body, docOverflow) : div.remove();

        return result;
    }

    // get subpixel support value

    function percentageLayout() {
        // check subpixel layout supporting
        var doc = document,
            body = getBody(),
            docOverflow = setFakeBody(body),
            wrapper = doc.createElement('div'),
            outer = doc.createElement('div'),
            str = '',
            count = 70,
            perPage = 3,
            supported = false;

        wrapper.className = "tns-t-subp2";
        outer.className = "tns-t-ct";

        for (var i = 0; i < count; i++) {
            str += '<div></div>';
        }

        outer.innerHTML = str;
        wrapper.appendChild(outer);
        body.appendChild(wrapper);

        supported = Math.abs(wrapper.getBoundingClientRect().left - outer.children[count - perPage].getBoundingClientRect().left) < 2;

        body.fake ? resetFakeBody(body, docOverflow) : wrapper.remove();

        return supported;
    }

    function mediaquerySupport() {
        var doc = document,
            body = getBody(),
            docOverflow = setFakeBody(body),
            div = doc.createElement('div'),
            style = doc.createElement('style'),
            rule = '@media all and (min-width:1px){.tns-mq-test{position:absolute}}',
            position;

        style.type = 'text/css';
        div.className = 'tns-mq-test';

        body.appendChild(style);
        body.appendChild(div);

        if (style.styleSheet) {
            style.styleSheet.cssText = rule;
        } else {
            style.appendChild(doc.createTextNode(rule));
        }

        position = window.getComputedStyle ? window.getComputedStyle(div).position : div.currentStyle['position'];

        body.fake ? resetFakeBody(body, docOverflow) : div.remove();

        return position === "absolute";
    }

    // create and append style sheet
    function createStyleSheet(media) {
        // Create the <style> tag
        var style = document.createElement("style");
        // style.setAttribute("type", "text/css");

        // Add a media (and/or media query) here if you'd like!
        // style.setAttribute("media", "screen")
        // style.setAttribute("media", "only screen and (max-width : 1024px)")
        if (media) { style.setAttribute("media", media); }

        // WebKit hack :(
        // style.appendChild(document.createTextNode(""));

        // Add the <style> element to the page
        document.querySelector('head').appendChild(style);

        return style.sheet ? style.sheet : style.styleSheet;
    }

    // cross browsers addRule method
    function addCSSRule(sheet, selector, rules, index) {
        // return raf(function() {
        'insertRule' in sheet ?
            sheet.insertRule(selector + '{' + rules + '}', index) :
            sheet.addRule(selector, rules, index);
        // });
    }

    // cross browsers addRule method
    function removeCSSRule(sheet, index) {
        // return raf(function() {
        'deleteRule' in sheet ?
            sheet.deleteRule(index) :
            sheet.removeRule(index);
        // });
    }

    function getCssRulesLength(sheet) {
        var rule = ('insertRule' in sheet) ? sheet.cssRules : sheet.rules;
        return rule.length;
    }

    function toDegree(y, x) {
        return Math.atan2(y, x) * (180 / Math.PI);
    }

    function getTouchDirection(angle, range) {
        var direction = false,
            gap = Math.abs(90 - Math.abs(angle));

        if (gap >= 90 - range) {
            direction = 'horizontal';
        } else if (gap <= range) {
            direction = 'vertical';
        }

        return direction;
    }

    // https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
    function forEachNodeList(arr, callback, scope) {
        for (var i = 0, l = arr.length; i < l; i++) {
            callback.call(scope, arr[i], i);
        }
    }

    var classListSupport = 'classList' in document.createElement('_');

    var hasClass = classListSupport ?
        function (el, str) { return el.classList.contains(str); } :
        function (el, str) { return el.className.indexOf(str) >= 0; };

    var addClass = classListSupport ?
        function (el, str) {
            if (!hasClass(el, str)) { el.classList.add(str); }
        } :
        function (el, str) {
            if (!hasClass(el, str)) { el.className += ' ' + str; }
        };

    var removeClass = classListSupport ?
        function (el, str) {
            if (hasClass(el, str)) { el.classList.remove(str); }
        } :
        function (el, str) {
            if (hasClass(el, str)) { el.className = el.className.replace(str, ''); }
        };

    function hasAttr(el, attr) {
        return el.hasAttribute(attr);
    }

    function getAttr(el, attr) {
        return el.getAttribute(attr);
    }

    function isNodeList(el) {
        // Only NodeList has the "item()" function
        return typeof el.item !== "undefined";
    }

    function setAttrs(els, attrs) {
        els = (isNodeList(els) || els instanceof Array) ? els : [els];
        if (Object.prototype.toString.call(attrs) !== '[object Object]') { return; }

        for (var i = els.length; i--;) {
            for (var key in attrs) {
                els[i].setAttribute(key, attrs[key]);
            }
        }
    }

    function removeAttrs(els, attrs) {
        els = (isNodeList(els) || els instanceof Array) ? els : [els];
        attrs = (attrs instanceof Array) ? attrs : [attrs];

        var attrLength = attrs.length;
        for (var i = els.length; i--;) {
            for (var j = attrLength; j--;) {
                els[i].removeAttribute(attrs[j]);
            }
        }
    }

    function removeElementStyles(el) {
        el.style.cssText = '';
    }

    function arrayFromNodeList(nl) {
        var arr = [];
        for (var i = 0, l = nl.length; i < l; i++) {
            arr.push(nl[i]);
        }
        return arr;
    }

    function hideElement(el) {
        if (!hasAttr(el, 'hidden')) {
            setAttrs(el, { 'hidden': '' });
        }
    }

    function showElement(el) {
        if (hasAttr(el, 'hidden')) {
            removeAttrs(el, 'hidden');
        }
    }

    function isVisible(el) {
        return el.offsetWidth > 0 && el.offsetHeight > 0;
    }

    function whichProperty(props) {
        if (typeof props === 'string') {
            var arr = [props],
                Props = props.charAt(0).toUpperCase() + props.substr(1),
                prefixes = ['Webkit', 'Moz', 'ms', 'O'];

            prefixes.forEach(function (prefix) {
                if (prefix !== 'ms' || props === 'transform') {
                    arr.push(prefix + Props);
                }
            });

            props = arr;
        }

        var el = document.createElement('fakeelement'),
            len = props.length;
        for (var i = 0; i < props.length; i++) {
            var prop = props[i];
            if (el.style[prop] !== undefined) { return prop; }
        }

        return false; // explicit for ie9-
    }

    function has3D(tf) {
        if (!tf) { return false; }
        if (!window.getComputedStyle) { return false; }

        var doc = document,
            body = getBody(),
            docOverflow = setFakeBody(body),
            el = doc.createElement('p'),
            has3d,
            cssTF = tf.length > 9 ? '-' + tf.slice(0, -9).toLowerCase() + '-' : '';

        cssTF += 'transform';

        // Add it to the body to get the computed style
        body.insertBefore(el, null);

        el.style[tf] = 'translate3d(1px,1px,1px)';
        has3d = window.getComputedStyle(el).getPropertyValue(cssTF);

        body.fake ? resetFakeBody(body, docOverflow) : el.remove();

        return (has3d !== undefined && has3d.length > 0 && has3d !== "none");
    }

    // get transitionend, animationend based on transitionDuration
    // @propin: string
    // @propOut: string, first-letter uppercase
    // Usage: getEndProperty('WebkitTransitionDuration', 'Transition') => webkitTransitionEnd
    function getEndProperty(propIn, propOut) {
        var endProp = false;
        if (/^Webkit/.test(propIn)) {
            endProp = 'webkit' + propOut + 'End';
        } else if (/^O/.test(propIn)) {
            endProp = 'o' + propOut + 'End';
        } else if (propIn) {
            endProp = propOut.toLowerCase() + 'end';
        }
        return endProp;
    }

    // Test via a getter in the options object to see if the passive property is accessed
    var supportsPassive = false;
    try {
        var opts = Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassive = true;
            }
        });
        window.addEventListener("test", null, opts);
    } catch (e) { }
    var passiveOption = supportsPassive ? { passive: true } : false;

    function addEvents(el, obj) {
        for (var prop in obj) {
            var option = (prop === 'touchstart' || prop === 'touchmove') ? passiveOption : false;
            el.addEventListener(prop, obj[prop], option);
        }
    }

    function removeEvents(el, obj) {
        for (var prop in obj) {
            var option = ['touchstart', 'touchmove'].indexOf(prop) >= 0 ? passiveOption : false;
            el.removeEventListener(prop, obj[prop], option);
        }
    }

    function Events() {
        return {
            topics: {},
            on: function (eventName, fn) {
                this.topics[eventName] = this.topics[eventName] || [];
                this.topics[eventName].push(fn);
            },
            off: function (eventName, fn) {
                if (this.topics[eventName]) {
                    for (var i = 0; i < this.topics[eventName].length; i++) {
                        if (this.topics[eventName][i] === fn) {
                            this.topics[eventName].splice(i, 1);
                            break;
                        }
                    }
                }
            },
            emit: function (eventName, data) {
                if (this.topics[eventName]) {
                    this.topics[eventName].forEach(function (fn) {
                        fn(data);
                    });
                }
            }
        };
    }

    function jsTransform(element, attr, prefix, postfix, to, duration, callback) {
        var tick = Math.min(duration, 10),
            unit = (to.indexOf('%') >= 0) ? '%' : 'px',
            to = to.replace(unit, ''),
            from = Number(element.style[attr].replace(prefix, '').replace(postfix, '').replace(unit, '')),
            positionTick = (to - from) / duration * tick,
            running;

        setTimeout(moveElement, tick);
        function moveElement() {
            duration -= tick;
            from += positionTick;
            element.style[attr] = prefix + from + unit + postfix;
            if (duration > 0) {
                setTimeout(moveElement, tick);
            } else {
                callback();
            }
        }
    }

    // Version: 2.8.1

    var tns = function (options) {
        options = extend({
            container: '.slider',
            mode: 'carousel',
            axis: 'horizontal',
            items: 1,
            gutter: 0,
            edgePadding: 0,
            fixedWidth: false,
            autoWidth: false,
            viewportMax: false,
            slideBy: 1,
            controls: true,
            controlsText: ['prev', 'next'],
            controlsContainer: false,
            prevButton: false,
            nextButton: false,
            nav: true,
            navContainer: false,
            navAsThumbnails: false,
            arrowKeys: false,
            speed: 300,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayDirection: 'forward',
            autoplayText: ['start', 'stop'],
            autoplayHoverPause: false,
            autoplayButton: false,
            autoplayButtonOutput: true,
            autoplayResetOnVisibility: true,
            // animateIn: 'tns-fadeIn',
            // animateOut: 'tns-fadeOut',
            // animateNormal: 'tns-normal',
            // animateDelay: false,
            loop: true,
            rewind: false,
            autoHeight: false,
            responsive: false,
            lazyload: false,
            touch: true,
            mouseDrag: false,
            swipeAngle: 15,
            nested: false,
            freezable: true,
            // startIndex: 0,
            onInit: false,
            useLocalStorage: true
        }, options || {});

        var doc = document,
            win = window,
            KEYS = {
                ENTER: 13,
                SPACE: 32,
                PAGEUP: 33,
                PAGEDOWN: 34,
                END: 35,
                HOME: 36,
                LEFT: 37,
                UP: 38,
                RIGHT: 39,
                DOWN: 40
            },
            tnsStorage = {},
            localStorageAccess = options.useLocalStorage;

        if (localStorageAccess) {
            // check browser version and local storage access
            var browserInfo = navigator.userAgent;
            var uid = new Date;

            try {
                tnsStorage = win.localStorage;
                if (tnsStorage) {
                    tnsStorage.setItem(uid, uid);
                    localStorageAccess = tnsStorage.getItem(uid) == uid;
                    tnsStorage.removeItem(uid);
                } else {
                    localStorageAccess = false;
                }
                if (!localStorageAccess) { tnsStorage = {}; }
            } catch (e) {
                localStorageAccess = false;
            }

            if (localStorageAccess) {
                // remove storage when browser version changes
                if (tnsStorage['tnsApp'] && tnsStorage['tnsApp'] !== browserInfo) {
                    ['tC', 'tPL', 'tMQ', 'tTf', 't3D', 'tTDu', 'tTDe', 'tADu', 'tADe', 'tTE', 'tAE'].forEach(function (item) { tnsStorage.removeItem(item); });
                }
                // update browserInfo
                localStorage['tnsApp'] = browserInfo;
            }
        }

        function getSetConstant(name, value) {
            return tnsStorage[name] ?
                checkStorageValue(tnsStorage[name]) :
                setLocalStorage(tnsStorage, name, value, localStorageAccess);
        }

        var CALC = getSetConstant('tC', calc()),
            PERCENTAGELAYOUT = getSetConstant('tPL', percentageLayout()),
            CSSMQ = getSetConstant('tMQ', mediaquerySupport()),
            TRANSFORM = getSetConstant('tTf', whichProperty('transform')),
            HAS3D = getSetConstant('t3D', has3D(TRANSFORM)),
            TRANSITIONDURATION = getSetConstant('tTDu', whichProperty('transitionDuration')),
            TRANSITIONDELAY = getSetConstant('tTDe', whichProperty('transitionDelay')),
            ANIMATIONDURATION = getSetConstant('tADu', whichProperty('animationDuration')),
            ANIMATIONDELAY = getSetConstant('tADe', whichProperty('animationDelay')),
            TRANSITIONEND = getSetConstant('tTE', getEndProperty(TRANSITIONDURATION, 'Transition')),
            ANIMATIONEND = getSetConstant('tAE', getEndProperty(ANIMATIONDURATION, 'Animation'));

        // get element nodes from selectors
        var supportConsoleWarn = win.console && typeof win.console.warn === "function",
            list = ['container', 'controlsContainer', 'prevButton', 'nextButton', 'navContainer', 'autoplayButton'];
        for (var i = list.length; i--;) {
            var item = list[i];
            if (typeof options[item] === 'string') {
                var el = doc.querySelector(options[item]);

                if (el && el.nodeName) {
                    options[item] = el;
                } else {
                    if (supportConsoleWarn) { console.warn('Can\'t find', options[item]); }
                    return;
                }
            }
        }

        // make sure at least 1 slide
        if (options.container.children.length < 1) {
            if (supportConsoleWarn) { console.warn('No slides found in', options.container); }
            return;
        }

        // update responsive
        // from: { 
        //    300: 2, 
        //    800: {
        //      loop: false
        //    }
        // }
        // to: {
        //    300: { 
        //      items: 2 
        //    }, 
        //    800: {
        //      loop: false
        //    }
        // }
        if (options.responsive) {
            var resTem = {}, res = options.responsive;
            for (var key in res) {
                var val = res[key];
                resTem[key] = typeof val === 'number' ? { items: val } : val;
            }

            options.responsive = resTem;
            resTem = null;

            // apply responsive[0] to options and remove it
            if (0 in options.responsive) {
                options = extend(options, options.responsive[0]);
                delete options.responsive[0];
            }
        }

        // === define and set variables ===
        var carousel = options.mode === 'carousel' ? true : false;

        if (!carousel) {
            options.axis = 'horizontal';
            options.edgePadding = false;

            var animateIn = 'tns-fadeIn',
                animateOut = 'tns-fadeOut',
                animateDelay = false,
                animateNormal = options.animateNormal || 'tns-normal';

            if (TRANSITIONEND && ANIMATIONEND) {
                animateIn = options.animateIn || animateIn;
                animateOut = options.animateOut || animateOut;
                animateDelay = options.animateDelay || animateDelay;
            }
        }

        var horizontal = options.axis === 'horizontal' ? true : false,
            outerWrapper = doc.createElement('div'),
            innerWrapper = doc.createElement('div'),
            container = options.container,
            containerParent = container.parentNode,
            slideItems = container.children,
            slideCount = slideItems.length,
            vpOuter = getViewportWidth(containerParent),
            responsive = options.responsive,
            responsiveItems = [],
            breakpoints = false,
            breakpointZone = 0,
            windowWidth = getWindowWidth(),
            isOn;


        if (responsive) {
            breakpoints = Object.keys(responsive)
                .map(function (x) { return parseInt(x); })
                .sort(function (a, b) { return a - b; });

            // get all responsive items
            breakpoints.forEach(function (bp) {
                responsiveItems = responsiveItems.concat(Object.keys(responsive[bp]));
            });

            // remove duplicated items
            var arr = [];
            responsiveItems.forEach(function (item) { if (arr.indexOf(item) < 0) { arr.push(item); } });
            responsiveItems = arr;

            setBreakpointZone();
        }

        // fixedWidth: vpOuter > vpInner > rightBoundary > indexMax
        var nested = options.nested,
            autoWidth = options.autoWidth,
            fixedWidth = getOption('fixedWidth'),
            edgePadding = getOption('edgePadding'),
            gutter = getOption('gutter'),
            vpInner = getViewportWidthInner(),
            items = !autoWidth ? Math.floor(getOption('items')) : null,
            slideBy = getOption('slideBy') === 'page' ? items : getOption('slideBy'),
            viewportMax = options.viewportMax || options.fixedWidthViewportWidth,
            arrowKeys = getOption('arrowKeys'),
            speed = getOption('speed'),
            rewind = options.rewind,
            loop = rewind ? false : options.loop,
            autoHeight = getOption('autoHeight'),
            sheet = createStyleSheet(),
            lazyload = options.lazyload,
            slidePositions, // collection of slide positions
            slideItemsOut = [],
            hasEdgePadding = checkOption('edgePadding'),
            cloneCount = loop ? getCloneCountForLoop() : 0,
            slideCountNew = !carousel ? slideCount + cloneCount : slideCount + cloneCount * 2,
            hasRightDeadZone = (fixedWidth || autoWidth) && !loop ? true : false,
            rightBoundary = fixedWidth ? getRightBoundary() : null,
            updateIndexBeforeTransform = (!carousel || !loop) ? true : false,
            // transform
            transformAttr = horizontal ? 'left' : 'top',
            transformPrefix = '',
            transformPostfix = '',
            // index
            startIndex = getOption('startIndex'),
            index = startIndex ? updateStartIndex(startIndex) : !carousel ? 0 : cloneCount,
            indexCached = index,
            indexMin = 0,
            indexMax = !autoWidth ? getIndexMax() : null,
            // resize
            resizeTimer,
            swipeAngle = options.swipeAngle,
            moveDirectionExpected = swipeAngle ? '?' : true,
            running = false,
            onInit = options.onInit,
            events = new Events(),
            // id, class
            containerIdCached = container.id,
            classContainer = ' tns-slider tns-' + options.mode,
            slideId = container.id || getSlideId(),
            disable = getOption('disable'),
            freezable = options.freezable,
            freeze = !autoWidth ? getFreeze() : null,
            frozen,
            importantStr = nested === 'inner' ? ' !important' : '',
            controlsEvents = {
                'click': onControlsClick,
                'keydown': onControlsKeydown
            },
            navEvents = {
                'click': onNavClick,
                'keydown': onNavKeydown
            },
            hoverEvents = {
                'mouseover': mouseoverPause,
                'mouseout': mouseoutRestart
            },
            visibilityEvent = { 'visibilitychange': onVisibilityChange },
            docmentKeydownEvent = { 'keydown': onDocumentKeydown },
            touchEvents = {
                'touchstart': onPanStart,
                'touchmove': onPanMove,
                'touchend': onPanEnd,
                'touchcancel': onPanEnd
            }, dragEvents = {
                'mousedown': onPanStart,
                'mousemove': onPanMove,
                'mouseup': onPanEnd,
                'mouseleave': onPanEnd
            },
            hasControls = checkOption('controls'),
            hasNav = checkOption('nav'),
            navAsThumbnails = autoWidth ? true : options.navAsThumbnails,
            hasAutoplay = checkOption('autoplay'),
            hasTouch = checkOption('touch'),
            hasMouseDrag = checkOption('mouseDrag'),
            slideActiveClass = 'tns-slide-active',
            imgCompleteClass = 'tns-complete',
            imgEvents = {
                'load': imgLoadedOrError,
                'error': imgLoadedOrError
            },
            imgsComplete;

        // controls
        if (hasControls) {
            var controls = getOption('controls'),
                controlsText = getOption('controlsText'),
                controlsContainer = options.controlsContainer,
                prevButton = options.prevButton,
                nextButton = options.nextButton,
                prevIsButton,
                nextIsButton;
        }

        // nav
        if (hasNav) {
            var nav = getOption('nav'),
                navContainer = options.navContainer,
                navItems,
                visibleNavIndexes = [],
                visibleNavIndexesCached = visibleNavIndexes,
                navClicked = -1,
                navCurrentIndex = getAbsIndex(),
                navCurrentIndexCached = navCurrentIndex,
                navActiveClass = 'tns-nav-active';
        }

        // autoplay
        if (hasAutoplay) {
            var autoplay = getOption('autoplay'),
                autoplayTimeout = getOption('autoplayTimeout'),
                autoplayDirection = options.autoplayDirection === 'forward' ? 1 : -1,
                autoplayText = getOption('autoplayText'),
                autoplayHoverPause = getOption('autoplayHoverPause'),
                autoplayButton = options.autoplayButton,
                autoplayResetOnVisibility = getOption('autoplayResetOnVisibility'),
                autoplayHtmlStrings = ['<span class=\'tns-visually-hidden\'>', ' animation</span>'],
                autoplayTimer,
                animating,
                autoplayHoverPaused,
                autoplayUserPaused,
                autoplayVisibilityPaused;
        }

        if (hasTouch || hasMouseDrag) {
            var initPosition = {},
                lastPosition = {},
                translateInit,
                disX,
                disY,
                panStart = false,
                rafIndex = 0,
                getDist = horizontal ?
                    function (a, b) { return a.x - b.x; } :
                    function (a, b) { return a.y - b.y; };
        }

        // touch
        if (hasTouch) {
            var touch = getOption('touch');
        }

        // mouse drag
        if (hasMouseDrag) {
            var mouseDrag = getOption('mouseDrag');
        }

        // disable slider when slidecount <= items
        if (freeze) {
            controls = nav = touch = mouseDrag = arrowKeys = autoplay = autoplayHoverPause = autoplayResetOnVisibility = false;
        }

        if (TRANSFORM) {
            transformAttr = TRANSFORM;
            transformPrefix = 'translate';

            if (HAS3D) {
                transformPrefix += horizontal ? '3d(' : '3d(0px, ';
                transformPostfix = horizontal ? ', 0px, 0px)' : ', 0px)';
            } else {
                transformPrefix += horizontal ? 'X(' : 'Y(';
                transformPostfix = ')';
            }

        }

        sliderInit();

        // === COMMON FUNCTIONS === //
        function getIndexMax() {
            if (!fixedWidth && !autoWidth) {
                return loop || carousel ? Math.max(0, slideCountNew - Math.ceil(getOption('items'))) : slideCountNew - 1;
            } else {
                if (fixedWidth) {
                    return Math.floor(- rightBoundary / (fixedWidth + gutter)) + 1;
                } else {
                    var i = slideCountNew - 1,
                        result = i;
                    while (slidePositions[i] > - rightBoundary) {
                        i--;
                        result = i;
                    }
                    return result;
                }
            }
        }

        function updateStartIndex(indexTem) {
            indexTem = indexTem % slideCount;
            if (indexTem < 0) { indexTem += slideCount; }
            indexTem = Math.min(indexTem, slideCountNew - items);
            return indexTem;
        }

        function getAbsIndex(i) {
            if (i === undefined) { i = index; }

            var min = carousel ? cloneCount : 0;
            while (i < min) { i += slideCount; }
            if (carousel) { i -= cloneCount; }

            return i ? Math.floor(i % slideCount) : i;
        }

        function getItemsMax() {
            // fixedWidth or autoWidth while viewportMax is not available
            if (autoWidth || (fixedWidth && !viewportMax)) {
                return slideCount - 1;
                // most cases
            } else {
                var str = fixedWidth ? 'fixedWidth' : 'items',
                    arr = [];

                if (fixedWidth || options[str] < slideCount) { arr.push(options[str]); }

                if (breakpoints && responsiveItems.indexOf(str) >= 0) {
                    breakpoints.forEach(function (bp) {
                        var tem = responsive[bp][str];
                        if (tem && (fixedWidth || tem < slideCount)) { arr.push(tem); }
                    });
                }

                if (!arr.length) { arr.push(0); }

                return Math.ceil(fixedWidth ? viewportMax / Math.min.apply(null, arr) : Math.max.apply(null, arr));
            }
        }

        function getCloneCountForLoop() {
            var itemsMax = getItemsMax(),
                result = carousel ? Math.ceil((itemsMax * 5 - slideCount) / 2) : (itemsMax * 4 - slideCount);
            result = Math.max(itemsMax, result);

            return hasEdgePadding ? result + 1 : result;
        }

        function getWindowWidth() {
            return win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth;
        }

        function getViewportWidth(el) {
            return el.clientWidth || getViewportWidth(el.parentNode);
        }

        function getViewportWidthInner() {
            return edgePadding ? vpOuter - (edgePadding * 2 - gutter) : vpOuter;
        }

        function checkOption(item) {
            var result = options[item];
            if (!result && breakpoints && responsiveItems.indexOf(item) >= 0) {
                breakpoints.forEach(function (bp) {
                    if (responsive[bp][item]) { result = true; }
                });
            }
            return result;
        }

        function getOption(item, viewport) {
            viewport = viewport ? viewport : windowWidth;

            var obj = {
                slideBy: 'page',
                edgePadding: false
            },
                result;

            if (!carousel && item in obj) {
                result = obj[item];
            } else {
                if (item === 'items' && fixedWidth) {
                    result = Math.floor(vpInner / (fixedWidth + gutter));
                } else if (item === 'autoHeight' && nested === 'outer') {
                    result = true;
                } else {
                    result = options[item];

                    if (breakpoints && responsiveItems.indexOf(item) >= 0) {
                        for (var i = 0, len = breakpoints.length; i < len; i++) {
                            var bp = breakpoints[i];
                            if (viewport >= bp) {
                                if (item in responsive[bp]) { result = responsive[bp][item]; }
                            } else { break; }
                        }
                    }
                }
            }

            if (item === 'slideBy' && result === 'page') { result = getOption('items'); }
            if (!carousel && (item === 'slideBy' || item === 'items')) { result = Math.floor(result); }

            return result;
        }

        function getSlideMarginLeft(i) {
            return CALC ?
                CALC + '(' + i * 100 + '% / ' + slideCountNew + ')' :
                i * 100 / slideCountNew + '%';
        }

        function getInnerWrapperStyles(edgePaddingTem, gutterTem, fixedWidthTem, speedTem) {
            var str = '';

            if (edgePaddingTem) {
                var gap = edgePaddingTem;
                if (gutterTem) { gap -= gutterTem; }
                str = horizontal ?
                    'margin: 0 ' + gap + 'px 0 ' + edgePaddingTem + 'px;' :
                    'margin: ' + edgePaddingTem + 'px 0 ' + gap + 'px 0;';
            } else if (gutterTem && !fixedWidthTem) {
                var gutterTemUnit = '-' + gutterTem + 'px',
                    dir = horizontal ? gutterTemUnit + ' 0 0' : '0 ' + gutterTemUnit + ' 0';
                str = 'margin: 0 ' + dir + ';';
            }

            if (TRANSITIONDURATION && speedTem) { str += getTrsnsitionDurationStyle(speedTem); }

            return str;
        }

        function getContainerWidth(fixedWidthTem, gutterTem, itemsTem) {
            if (fixedWidthTem) {
                return (fixedWidthTem + gutterTem) * slideCountNew + 'px';
            } else {
                return CALC ?
                    CALC + '(' + slideCountNew * 100 + '% / ' + itemsTem + ')' :
                    slideCountNew * 100 / itemsTem + '%';
            }
        }

        function getSlideWidthStyle(fixedWidthTem, gutterTem, itemsTem) {
            var width;

            if (fixedWidthTem) {
                width = (fixedWidthTem + gutterTem) + 'px';
            } else {
                if (!carousel) { itemsTem = Math.floor(itemsTem); }
                var dividend = carousel ? slideCountNew : itemsTem;
                width = CALC ?
                    CALC + '(100% / ' + dividend + ')' :
                    100 / dividend + '%';
            }

            return 'width:' + width + importantStr + ';';
        }

        function getSlideGutterStyle(gutterTem) {
            var str = '';

            // gutter maybe interger || 0
            // so can't use 'if (gutter)'
            if (gutterTem !== false) {
                var prop = horizontal ? 'padding-' : 'margin-',
                    dir = horizontal ? 'right' : 'bottom';
                str = prop + dir + ': ' + gutterTem + 'px;';
            }

            return str;
        }

        function getCSSPrefix(name, num) {
            var prefix = name.substring(0, name.length - num).toLowerCase();
            if (prefix) { prefix = '-' + prefix + '-'; }

            return prefix;
        }

        function getTrsnsitionDurationStyle(speed) {
            return getCSSPrefix(TRANSITIONDURATION, 18) + 'transition-duration:' + speed / 1000 + 's;';
        }

        function getAnimationDurationStyle(speed) {
            return getCSSPrefix(ANIMATIONDURATION, 17) + 'animation-duration:' + speed / 1000 + 's;';
        }

        function sliderInit() {
            var classOuter = 'tns-outer',
                classInner = 'tns-inner',
                hasGutter = checkOption('gutter');

            outerWrapper.className = classOuter;
            innerWrapper.className = classInner;
            innerWrapper.id = slideId + '-iw';
            if (autoHeight) { innerWrapper.className += ' tns-ah'; }

            // set container properties
            if (container.id === '') { container.id = slideId; }
            classContainer += PERCENTAGELAYOUT || autoWidth ? ' tns-subpixel' : ' tns-no-subpixel';
            classContainer += CALC ? ' tns-calc' : ' tns-no-calc';
            if (carousel) { classContainer += ' tns-' + options.axis; }
            container.className += classContainer;

            // add constrain layer for carousel
            if (carousel) {
                var middleWrapper = doc.createElement('div');
                middleWrapper.className = 'tns-ovh';

                outerWrapper.appendChild(middleWrapper);
                middleWrapper.appendChild(innerWrapper);
            } else {
                outerWrapper.appendChild(innerWrapper);
            }

            containerParent.insertBefore(outerWrapper, container);
            innerWrapper.appendChild(container);

            // add events
            if (carousel && TRANSITIONEND) {
                var eve = {};
                eve[TRANSITIONEND] = onTransitionEnd;
                addEvents(container, eve);
            }

            // add id, class, aria attributes 
            // before clone slides
            for (var x = 0; x < slideCount; x++) {
                var item = slideItems[x];
                if (!item.id) { item.id = slideId + '-item' + x; }
                addClass(item, 'tns-item');
                if (!carousel && animateNormal) { addClass(item, animateNormal); }
                setAttrs(item, {
                    'aria-hidden': 'true',
                    'tabindex': '-1'
                });
            }

            // ## clone slides
            // carousel: n + slides + n
            // gallery:      slides + n
            if (cloneCount) {
                var fragmentBefore = doc.createDocumentFragment(),
                    fragmentAfter = doc.createDocumentFragment();

                for (var j = cloneCount; j--;) {
                    var num = j % slideCount,
                        cloneFirst = slideItems[num].cloneNode(true);
                    removeAttrs(cloneFirst, 'id');
                    fragmentAfter.insertBefore(cloneFirst, fragmentAfter.firstChild);

                    if (carousel) {
                        var cloneLast = slideItems[slideCount - 1 - num].cloneNode(true);
                        removeAttrs(cloneLast, 'id');
                        fragmentBefore.appendChild(cloneLast);
                    }
                }

                container.insertBefore(fragmentBefore, container.firstChild);
                container.appendChild(fragmentAfter);
                slideItems = container.children;
            }

            // ## images loaded/failed
            if (checkOption('autoHeight') || !carousel || autoWidth || !horizontal) {
                var imgs = container.querySelectorAll('img');

                // add complete class if all images are loaded/failed
                forEachNodeList(imgs, function (img) {
                    var src = img.src;

                    if (src.indexOf('data:image') < 0) {
                        addEvents(img, imgEvents);
                        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                        img.src = src;
                    } else {
                        addClass(img, imgCompleteClass);
                    }
                });

                raf(function () {
                    imageLoaded(arrayFromNodeList(imgs), function () {
                        // set imgsComplete to true
                        imgsComplete = true;

                        // run Fn()s which are rely on image loading
                        if (!disable) {
                            if (!horizontal || autoWidth) {
                                getSlidePositions();
                                lazyLoad();

                                if (autoWidth) {
                                    items = getOption('items');
                                    rightBoundary = getRightBoundary();
                                    freeze = getFreeze();
                                    indexMax = getIndexMax();

                                    // update slider UI
                                    initUI();
                                }

                                if (!horizontal) { updateContentWrapperHeight(); }
                            }

                            // set container transform property
                            if (carousel) {
                                doContainerTransformSilent();
                            }
                        }
                    });
                });
            }

            if (carousel && horizontal && !autoWidth) { doContainerTransformSilent(); }

            initSheet();
            if (!autoWidth) { initUI(); }
        }

        function initSheet() {
            // activate visible slides
            // add aria attrs
            // set animation classes and left value for gallery slider
            // use slide count when slides are fewer than items
            for (var i = index, l = index + Math.min(slideCount, items); i < l; i++) {
                var item = slideItems[i];
                setAttrs(item, { 'aria-hidden': 'false' });
                removeAttrs(item, ['tabindex']);
                addClass(item, slideActiveClass);

                if (!carousel) {
                    item.style.left = (i - index) * 100 / items + '%';
                    addClass(item, animateIn);
                    removeClass(item, animateNormal);
                }
            }

            // #### LAYOUT

            // ## INLINE-BLOCK VS FLOAT

            // ## PercentageLayout:
            // slides: inline-block
            // remove blank space between slides by set font-size: 0

            // ## Non PercentageLayout:
            // slides: float
            //         margin-right: -100%
            //         margin-left: ~

            // Resource: https://docs.google.com/spreadsheets/d/147up245wwTXeQYve3BRSAD4oVcvQmuGsFteJOeA5xNQ/edit?usp=sharing
            if (carousel && horizontal) {
                if (PERCENTAGELAYOUT || autoWidth) {
                    addCSSRule(sheet, '#' + slideId + ' > .tns-item', 'font-size:' + win.getComputedStyle(slideItems[0]).fontSize + ';', getCssRulesLength(sheet));
                    addCSSRule(sheet, '#' + slideId, 'font-size:0;', getCssRulesLength(sheet));
                } else {
                    forEachNodeList(slideItems, function (slide, i) {
                        slide.style.marginLeft = getSlideMarginLeft(i);
                    });
                }
            }


            // ## BASIC STYLES
            if (CSSMQ) {
                // inner wrapper styles
                var str = getInnerWrapperStyles(options.edgePadding, options.gutter, options.fixedWidth, options.speed);
                addCSSRule(sheet, '#' + slideId + '-iw', str, getCssRulesLength(sheet));

                // container styles
                if (carousel) {
                    str = horizontal && !autoWidth ? 'width:' + getContainerWidth(options.fixedWidth, options.gutter, options.items) + ';' : '';
                    if (TRANSITIONDURATION) { str += getTrsnsitionDurationStyle(speed); }
                    addCSSRule(sheet, '#' + slideId, str, getCssRulesLength(sheet));
                }

                // slide styles
                str = horizontal && !autoWidth ? getSlideWidthStyle(options.fixedWidth, options.gutter, options.items) : '';
                if (options.gutter) { str += getSlideGutterStyle(options.gutter); }
                // set gallery items transition-duration
                if (!carousel) {
                    if (TRANSITIONDURATION) { str += getTrsnsitionDurationStyle(speed); }
                    if (ANIMATIONDURATION) { str += getAnimationDurationStyle(speed); }
                }
                if (str) { addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet)); }

                // non CSS mediaqueries: IE8
                // ## update inner wrapper, container, slides if needed
                // set inline styles for inner wrapper & container
                // insert stylesheet (one line) for slides only (since slides are many)
            } else {
                // inner wrapper styles
                innerWrapper.style.cssText = getInnerWrapperStyles(edgePadding, gutter, fixedWidth);

                // container styles
                if (carousel && horizontal && !autoWidth) {
                    container.style.width = getContainerWidth(fixedWidth, gutter, items);
                }

                // slide styles
                var str = horizontal && !autoWidth ? getSlideWidthStyle(fixedWidth, gutter, items) : '';
                if (gutter) { str += getSlideGutterStyle(gutter); }

                // append to the last line
                if (str) { addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet)); }
            }

            // ## MEDIAQUERIES
            if (responsive && CSSMQ) {
                breakpoints.forEach(function (bp) {
                    var opts = responsive[bp],
                        str = '',
                        innerWrapperStr = '',
                        containerStr = '',
                        slideStr = '',
                        itemsBP = !autoWidth ? getOption('items', bp) : null,
                        fixedWidthBP = getOption('fixedWidth', bp),
                        speedBP = getOption('speed', bp),
                        edgePaddingBP = getOption('edgePadding', bp),
                        gutterBP = getOption('gutter', bp);

                    // inner wrapper string
                    if ('edgePadding' in opts || 'gutter' in opts) {
                        innerWrapperStr = '#' + slideId + '-iw{' + getInnerWrapperStyles(edgePaddingBP, gutterBP, fixedWidthBP, speedBP) + '}';
                    }

                    // container string
                    if (carousel && horizontal && !autoWidth && ('fixedWidth' in opts || 'items' in opts || (fixedWidth && 'gutter' in opts))) {
                        containerStr = 'width:' + getContainerWidth(fixedWidthBP, gutterBP, itemsBP) + ';';
                    }
                    if (TRANSITIONDURATION && 'speed' in opts) {
                        containerStr += getTrsnsitionDurationStyle(speedBP);
                    }
                    if (containerStr) {
                        containerStr = '#' + slideId + '{' + containerStr + '}';
                    }

                    // slide string
                    if ('fixedWidth' in opts || (fixedWidth && 'gutter' in opts) || !carousel && 'items' in opts) {
                        slideStr += getSlideWidthStyle(fixedWidthBP, gutterBP, itemsBP);
                    }
                    if ('gutter' in opts) {
                        slideStr += getSlideGutterStyle(gutterBP);
                    }
                    // set gallery items transition-duration
                    if (!carousel && 'speed' in opts) {
                        if (TRANSITIONDURATION) { slideStr += getTrsnsitionDurationStyle(speedBP); }
                        if (ANIMATIONDURATION) { slideStr += getAnimationDurationStyle(speedBP); }
                    }
                    if (slideStr) { slideStr = '#' + slideId + ' > .tns-item{' + slideStr + '}'; }

                    // add up
                    str = innerWrapperStr + containerStr + slideStr;

                    if (str) {
                        sheet.insertRule('@media (min-width: ' + bp / 16 + 'em) {' + str + '}', sheet.cssRules.length);
                    }
                });
            }
        }

        function initUI() {
            // == msInit ==
            // for IE10
            if (navigator.msMaxTouchPoints) {
                addClass(container, 'ms-touch');
                addEvents(container, { 'scroll': ie10Scroll });
                setSnapInterval();
            }

            // == autoplayInit ==
            if (hasAutoplay) {
                var txt = autoplay ? 'stop' : 'start';
                if (autoplayButton) {
                    setAttrs(autoplayButton, { 'data-action': txt });
                } else if (options.autoplayButtonOutput) {
                    outerWrapper.insertAdjacentHTML('afterbegin', '<button data-action="' + txt + '" type="button">' + autoplayHtmlStrings[0] + txt + autoplayHtmlStrings[1] + autoplayText[0] + '</button>');
                    autoplayButton = outerWrapper.querySelector('[data-action]');
                }

                // add event
                if (autoplayButton) {
                    addEvents(autoplayButton, { 'click': toggleAutoplay });
                }

                if (!autoplay) {
                    if (autoplayButton) {
                        hideElement(autoplayButton);
                    }
                } else {
                    startAutoplay();
                    if (autoplayHoverPause) { addEvents(container, hoverEvents); }
                    if (autoplayResetOnVisibility) { addEvents(container, visibilityEvent); }
                }
            }

            // == navInit ==
            if (hasNav) {
                var initIndex = !carousel ? 0 : cloneCount;
                // customized nav
                // will not hide the navs in case they're thumbnails
                if (navContainer) {
                    setAttrs(navContainer, { 'aria-label': 'Carousel Pagination' });
                    navItems = navContainer.children;
                    for (var i = 0; i < slideCount; i++) {
                        var item = navItems[i];
                        if (item) {
                            setAttrs(item, {
                                'data-nav': i,
                                'tabindex': '-1',
                                'aria-selected': 'false',
                                'aria-controls': slideItems[initIndex + i].id,
                            });
                        }
                    }

                    // generated nav 
                } else {
                    var navHtml = '',
                        hiddenStr = navAsThumbnails ? '' : 'hidden';
                    for (var i = 0; i < slideCount; i++) {
                        // hide nav items by default
                        navHtml += '<button data-nav="' + i + '" tabindex="-1" aria-selected="false" aria-controls="' + slideItems[initIndex + i].id + '" ' + hiddenStr + ' type="button"></button>';
                    }
                    navHtml = '<div class="tns-nav" aria-label="Carousel Pagination">' + navHtml + '</div>';
                    outerWrapper.insertAdjacentHTML('afterbegin', navHtml);

                    navContainer = outerWrapper.querySelector('.tns-nav');
                    navItems = navContainer.children;
                }

                updateNavVisibility();

                // add transition
                if (TRANSITIONDURATION) {
                    var prefix = TRANSITIONDURATION.substring(0, TRANSITIONDURATION.length - 18).toLowerCase(),
                        str = 'transition: all ' + speed / 1000 + 's';

                    if (prefix) {
                        str = '-' + prefix + '-' + str;
                    }

                    addCSSRule(sheet, '[aria-controls^=' + slideId + '-item]', str, getCssRulesLength(sheet));
                }

                setAttrs(navItems[navCurrentIndex], { 'tabindex': '0', 'aria-selected': 'true' });
                addClass(navItems[navCurrentIndex], navActiveClass);

                // add events
                addEvents(navContainer, navEvents);

                if (!nav) { hideElement(navContainer); }
            }



            // == controlsInit ==
            if (hasControls) {
                if (controlsContainer || (prevButton && nextButton)) {
                    if (controlsContainer) {
                        prevButton = controlsContainer.children[0];
                        nextButton = controlsContainer.children[1];
                        setAttrs(controlsContainer, {
                            'aria-label': 'Carousel Navigation',
                            'tabindex': '0'
                        });
                        setAttrs(controlsContainer.children, {
                            'aria-controls': slideId,
                            'tabindex': '-1',
                        });
                    }

                    setAttrs(prevButton, { 'data-controls': 'prev' });
                    setAttrs(nextButton, { 'data-controls': 'next' });
                } else {
                    outerWrapper.insertAdjacentHTML('afterbegin', '<div class="tns-controls" aria-label="Carousel Navigation" tabindex="0"><button data-controls="prev" tabindex="-1" aria-controls="' + slideId + '" type="button">' + controlsText[0] + '</button><button data-controls="next" tabindex="-1" aria-controls="' + slideId + '" type="button">' + controlsText[1] + '</button></div>');

                    controlsContainer = outerWrapper.querySelector('.tns-controls');
                    prevButton = controlsContainer.children[0];
                    nextButton = controlsContainer.children[1];
                }

                prevIsButton = isButton(prevButton);
                nextIsButton = isButton(nextButton);

                updateControlsStatus();

                // add events
                if (controlsContainer) {
                    addEvents(controlsContainer, controlsEvents);
                } else {
                    addEvents(prevButton, controlsEvents);
                    addEvents(nextButton, controlsEvents);
                }

                if (!controls) { hideElement(controlsContainer); }
            }


            if (touch) { addEvents(container, touchEvents); }
            if (mouseDrag) { addEvents(container, dragEvents); }
            if (arrowKeys) { addEvents(doc, docmentKeydownEvent); }


            if (nested === 'inner') {
                events.on('outerResized', function () {
                    resizeTasks();
                    events.emit('innerLoaded', info());
                });
            } else {
                addEvents(win, { 'resize': onResize });
            }

            if (nested === 'outer') {
                events.on('innerLoaded', runAutoHeight);
            } else if ((autoHeight || !carousel) && !disable) {
                runAutoHeight();
            }

            if (!autoWidth) { lazyLoad(); }
            toggleSlideDisplayAndEdgePadding();

            events.on('indexChanged', additionalUpdates);

            if (typeof onInit === 'function') { onInit(info()); }
            if (nested === 'inner') { events.emit('innerLoaded', info()); }

            if (disable) { disableSlider(true); }

            isOn = true;
        }





        // === ON RESIZE ===
        function onResize(e) {
            raf(function () { resizeTasks(getEvent(e)); });
        }

        function resizeTasks(e) {
            if (!isOn) { return; }

            windowWidth = getWindowWidth();
            if (nested === 'outer') { events.emit('outerResized', info(e)); }

            var breakpointZoneTem = breakpointZone,
                edgePaddingTem = edgePadding,
                gutterTem = gutter,
                indexTem = index,
                itemsTem = items,
                freezeTem = freeze,
                needContainerTransform = false;

            vpOuter = getViewportWidth(outerWrapper);
            edgePadding = getOption('edgePadding');
            gutter = getOption('gutter');
            vpInner = getViewportWidthInner();
            if (breakpoints) { setBreakpointZone(); }
            if (breakpointZoneTem !== breakpointZone) { events.emit('newBreakpointStart', info(e)); }
            if ((!horizontal || autoWidth) && !disable) {
                getSlidePositions();
                if (!horizontal) {
                    updateContentWrapperHeight();
                    needContainerTransform = true;
                }
            }
            if (fixedWidth || autoWidth) {
                rightBoundary = getRightBoundary();
                indexMax = getIndexMax();
            }

            // things do when breakpoint zone change
            if (breakpointZoneTem !== breakpointZone || fixedWidth || autoWidth) {
                var slideByTem = slideBy,
                    arrowKeysTem = arrowKeys,
                    autoHeightTem = autoHeight,
                    fixedWidthTem = fixedWidth,
                    disableTem = disable;

                // update variables
                items = getOption('items');
                slideBy = getOption('slideBy');
                disable = getOption('disable');
                if (hasRightDeadZone) { needContainerTransform = true; }
                freeze = getFreeze();

                if (items !== itemsTem) {
                    if (!fixedWidth && !autoWidth) { indexMax = getIndexMax(); }
                    // check index before transform in case
                    // slider reach the right edge then items become bigger
                    updateIndex();
                }

                if (disable !== disableTem) {
                    disableSlider(disable);
                }

                if (freeze !== freezeTem) {
                    // reset container transforms
                    if (freeze) {
                        doContainerTransform(getContainerTransformValue(!carousel ? 0 : cloneCount));
                    } else {
                        needContainerTransform = true;
                    }

                    toggleSlideDisplayAndEdgePadding();
                }

                if (breakpointZoneTem !== breakpointZone) {
                    speed = getOption('speed');

                    fixedWidth = getOption('fixedWidth');
                    if (!disable && fixedWidth !== fixedWidthTem) {
                        needContainerTransform = true;
                    }

                    autoHeight = getOption('autoHeight');
                    if (autoHeight !== autoHeightTem) {
                        if (!autoHeight) { innerWrapper.style.height = ''; }
                    }
                }

                arrowKeys = freeze ? false : getOption('arrowKeys');
                if (arrowKeys !== arrowKeysTem) {
                    arrowKeys ?
                        addEvents(doc, docmentKeydownEvent) :
                        removeEvents(doc, docmentKeydownEvent);
                }

                if (hasControls) {
                    var controlsTem = controls,
                        controlsTextTem = controlsText;
                    controls = freeze ? false : getOption('controls');
                    controlsText = getOption('controlsText');

                    if (controls !== controlsTem) {
                        controls ?
                            showElement(controlsContainer) :
                            hideElement(controlsContainer);
                    }
                    if (controlsText !== controlsTextTem) {
                        prevButton.innerHTML = controlsText[0];
                        nextButton.innerHTML = controlsText[1];
                    }
                }
                if (hasNav) {
                    var navTem = nav;
                    nav = freeze ? false : getOption('nav');

                    if (nav !== navTem) {
                        if (nav) {
                            showElement(navContainer);
                            updateNavVisibility();
                        } else {
                            hideElement(navContainer);
                        }
                    }
                }
                if (hasTouch) {
                    var touchTem = touch;
                    touch = freeze ? false : getOption('touch');

                    if (touch !== touchTem && carousel) {
                        touch ?
                            addEvents(container, touchEvents) :
                            removeEvents(container, touchEvents);
                    }
                }
                if (hasMouseDrag) {
                    var mouseDragTem = mouseDrag;
                    mouseDrag = freeze ? false : getOption('mouseDrag');

                    if (mouseDrag !== mouseDragTem && carousel) {
                        mouseDrag ?
                            addEvents(container, dragEvents) :
                            removeEvents(container, dragEvents);
                    }
                }
                if (hasAutoplay) {
                    var autoplayTem = autoplay,
                        autoplayHoverPauseTem = autoplayHoverPause,
                        autoplayResetOnVisibilityTem = autoplayResetOnVisibility,
                        autoplayTextTem = autoplayText;

                    if (freeze) {
                        autoplay = autoplayHoverPause = autoplayResetOnVisibility = false;
                    } else {
                        autoplay = getOption('autoplay');

                        if (autoplay) {
                            autoplayHoverPause = getOption('autoplayHoverPause');
                            autoplayResetOnVisibility = getOption('autoplayResetOnVisibility');
                        } else {
                            autoplayHoverPause = autoplayResetOnVisibility = false;
                        }
                    }
                    autoplayText = getOption('autoplayText');
                    autoplayTimeout = getOption('autoplayTimeout');

                    if (autoplay !== autoplayTem) {
                        if (autoplay) {
                            if (autoplayButton) { showElement(autoplayButton); }
                            if (!animating && !autoplayUserPaused) { startAutoplay(); }
                        } else {
                            if (autoplayButton) { hideElement(autoplayButton); }
                            if (animating) { stopAutoplay(); }
                        }
                    }
                    if (autoplayHoverPause !== autoplayHoverPauseTem) {
                        autoplayHoverPause ?
                            addEvents(container, hoverEvents) :
                            removeEvents(container, hoverEvents);
                    }
                    if (autoplayResetOnVisibility !== autoplayResetOnVisibilityTem) {
                        autoplayResetOnVisibility ?
                            addEvents(doc, visibilityEvent) :
                            removeEvents(doc, visibilityEvent);
                    }
                    if (autoplayButton && autoplayText !== autoplayTextTem) {
                        var i = autoplay ? 1 : 0,
                            html = autoplayButton.innerHTML,
                            len = html.length - autoplayTextTem[i].length;
                        if (html.substring(len) === autoplayTextTem[i]) {
                            autoplayButton.innerHTML = html.substring(0, len) + autoplayText[i];
                        }
                    }
                }

                // non-meduaqueries: IE8
                // ## update inner wrapper, container, slides if needed
                // set inline styles for inner wrapper & container
                // insert stylesheet (one line) for slides only (since slides are many)
                if (!CSSMQ) {
                    // inner wrapper styles
                    if (!freeze && (edgePadding !== edgePaddingTem || gutter !== gutterTem)) {
                        innerWrapper.style.cssText = getInnerWrapperStyles(edgePadding, gutter, fixedWidth);
                    }

                    if (horizontal && !fixedWidth) {
                        // container styles
                        if (carousel) {
                            container.style.width = getContainerWidth(false, null, items);
                        }

                        // slide styles
                        var str = getSlideWidthStyle(fixedWidth, gutter, items) +
                            getSlideGutterStyle(gutter);

                        // remove the last line and
                        // add new styles
                        removeCSSRule(sheet, getCssRulesLength(sheet) - 1);
                        addCSSRule(sheet, '#' + slideId + ' > .tns-item', str, getCssRulesLength(sheet));
                    }

                    if (!fixedWidth) { needContainerTransform = true; }
                }

                if (index !== indexTem) {
                    events.emit('indexChanged', info());
                    needContainerTransform = true;
                }

                // items is not updated in autoWidth slider
                if (items !== itemsTem || autoWidth) {
                    additionalUpdates();
                    updateSlidePosition();

                    if (navigator.msMaxTouchPoints) { setSnapInterval(); }
                }
            }

            if (needContainerTransform) {
                doContainerTransformSilent();
                indexCached = index;
            }

            // auto height
            if ((autoHeight || !carousel) && !disable) { runAutoHeight(); }

            if (breakpointZoneTem !== breakpointZone) { events.emit('newBreakpointEnd', info(e)); }
        }





        // === INITIALIZATION FUNCTIONS === //
        function getFreeze() {
            if (disable) {
                return true;
            } else if (!freezable) {
                return false;
            } else if (!fixedWidth && !autoWidth) {
                return slideCount <= items;
            } else {
                if (loop) {
                    var width = fixedWidth ? (fixedWidth + gutter) * slideCount : slidePositions[slideCount];
                    return width - gutter <= vpInner;
                } else {
                    return !rightBoundary;
                }
            }
        }

        function setBreakpointZone() {
            breakpointZone = 0;
            breakpoints.forEach(function (bp, i) {
                if (windowWidth >= bp) { breakpointZone = i + 1; }
            });
        }

        // (slideBy, indexMin, indexMax) => index
        var updateIndex = (function () {
            return loop ?
                carousel ?
                    // loop + carousel
                    function () {
                        var leftEdge = indexMin,
                            rightEdge = indexMax;

                        leftEdge += slideBy;
                        rightEdge -= slideBy;

                        // adjust edges when edge padding is true
                        // or fixed-width slider with extra space on the right side
                        if (edgePadding) {
                            leftEdge += 1;
                            rightEdge -= 1;
                        } else if (fixedWidth) {
                            var gt = gutter ? gutter : 0;
                            if (vpOuter % (fixedWidth + gt) > gt) { rightEdge -= 1; }
                        }

                        if (cloneCount) {
                            if (index > rightEdge) {
                                index -= slideCount;
                            } else if (index < leftEdge) {
                                index += slideCount;
                            }
                        }
                    } :
                    // loop + gallery
                    function () {
                        if (index > indexMax) {
                            while (index >= indexMin + slideCount) { index -= slideCount; }
                        } else if (index < indexMin) {
                            while (index <= indexMax - slideCount) { index += slideCount; }
                        }
                    } :
                // non-loop
                function () {
                    index = Math.max(indexMin, Math.min(indexMax, index));
                };
        })();

        function toggleSlideDisplayAndEdgePadding() {
            var str = 'tns-transparent';

            if (freeze) {
                if (!frozen) {
                    // remove edge padding from inner wrapper
                    if (edgePadding) { innerWrapper.style.margin = '0px'; }

                    // add class tns-transparent to cloned slides
                    if (cloneCount) {
                        for (var i = cloneCount; i--;) {
                            if (carousel) { addClass(slideItems[i], str); }
                            addClass(slideItems[slideCountNew - i - 1], str);
                        }
                    }

                    frozen = true;
                }
            } else if (frozen) {
                // restore edge padding for inner wrapper
                // for mordern browsers
                if (edgePadding && CSSMQ) { innerWrapper.style.margin = ''; }

                // remove class tns-transparent to cloned slides
                if (cloneCount) {
                    for (var i = cloneCount; i--;) {
                        if (carousel) { removeClass(slideItems[i], str); }
                        removeClass(slideItems[slideCountNew - i - 1], str);
                    }
                }

                frozen = false;
            }
        }

        function disableSlider(disable) {
            var len = slideItems.length;

            if (disable) {
                sheet.disabled = true;
                container.className = container.className.replace(classContainer.substring(1), '');
                removeElementStyles(container);
                if (loop) {
                    for (var j = cloneCount; j--;) {
                        if (carousel) { hideElement(slideItems[j]); }
                        hideElement(slideItems[len - j - 1]);
                    }
                }

                // vertical slider
                if (!horizontal || !carousel) { removeElementStyles(innerWrapper); }

                // gallery
                if (!carousel) {
                    for (var i = index, l = index + slideCount; i < l; i++) {
                        var item = slideItems[i];
                        removeElementStyles(item);
                        removeClass(item, animateIn);
                        removeClass(item, animateNormal);
                    }
                }
            } else {
                sheet.disabled = false;
                container.className += classContainer;

                // vertical slider: get offsetTops before container transform
                if (!horizontal || autoWidth) {
                    getSlidePositions();
                    if (autoWidth) {
                        rightBoundary = getRightBoundary();
                        indexMax = getIndexMax();
                    }
                }

                doContainerTransformSilent();
                if (loop) {
                    for (var j = cloneCount; j--;) {
                        if (carousel) { showElement(slideItems[j]); }
                        showElement(slideItems[len - j - 1]);
                    }
                }

                // gallery
                if (!carousel) {
                    for (var i = index, l = index + slideCount; i < l; i++) {
                        var item = slideItems[i],
                            classN = i < index + items ? animateIn : animateNormal;
                        item.style.left = (i - index) * 100 / items + '%';
                        addClass(item, classN);
                    }
                }
            }
        }

        function lazyLoad() {
            if (lazyload && !disable) {
                var i = index, len;

                if (!autoWidth) {
                    len = index + items;
                } else {
                    var a = index + 1,
                        len = a,
                        edge = slidePositions[index] + vpInner + edgePadding - gutter;
                    while (slidePositions[a] < edge) {
                        a++;
                        len = a;
                    }
                }

                if (edgePadding) {
                    i -= 1;
                    if (!autoWidth) { len += 1; }
                }

                i = Math.floor(Math.max(i, 0));
                len = Math.ceil(Math.min(len, slideCountNew));

                for (; i < len; i++) {
                    forEachNodeList(slideItems[i].querySelectorAll('.tns-lazy-img'), function (img) {
                        // stop propagationl transitionend event to container
                        var eve = {};
                        eve[TRANSITIONEND] = function (e) { e.stopPropagation(); };
                        addEvents(img, eve);

                        if (!hasClass(img, 'loaded')) {
                            img.src = getAttr(img, 'data-src');
                            addClass(img, 'loaded');
                        }
                    });
                }
            }
        }


        function imgLoadedOrError(e) {
            var img = getTarget(e);
            addClass(img, imgCompleteClass);
            removeEvents(img, imgEvents);
        }

        function getImageArray(slideStart, slideRange) {
            var imgs = [];
            for (var i = slideStart, l = Math.min(slideStart + slideRange, slideCountNew); i < l; i++) {
                forEachNodeList(slideItems[i].querySelectorAll('img'), function (img) {
                    imgs.push(img);
                });
            }

            return imgs;
        }

        // check if all visible images are loaded
        // and update container height if it's done
        function runAutoHeight() {
            var imgs = autoHeight ?
                getImageArray(index, items) :
                getImageArray(cloneCount, slideCount);

            raf(function () { imageLoaded(imgs, updateInnerWrapperHeight); });
        }

        function imageLoaded(imgs, cb) {
            // directly execute callback function if all images are complete
            if (imgsComplete) { return cb(); }

            // check selected image classes otherwise
            imgs.forEach(function (img, index) {
                if (hasClass(img, imgCompleteClass)) { imgs.splice(index, 1); }
            });

            // execute callback function if selected images are all complete
            if (!imgs.length) { return cb(); }

            // otherwise execute this functiona again
            raf(function () { imageLoaded(imgs, cb); });
        }

        function additionalUpdates() {
            lazyLoad();
            updateSlideStatus();
            updateControlsStatus();
            updateNavVisibility();
            updateNavStatus();
        }


        function getMaxSlideHeight(slideStart, slideRange) {
            var heights = [];
            for (var i = slideStart, l = Math.min(slideStart + slideRange, slideCountNew); i < l; i++) {
                heights.push(slideItems[i].offsetHeight);
            }

            return Math.max.apply(null, heights);
        }

        // update inner wrapper height
        // 1. get the max-height of the visible slides
        // 2. set transitionDuration to speed
        // 3. update inner wrapper height to max-height
        // 4. set transitionDuration to 0s after transition done
        function updateInnerWrapperHeight() {
            var maxHeight = autoHeight ?
                getMaxSlideHeight(index, items) :
                getMaxSlideHeight(cloneCount, slideCount);

            if (innerWrapper.style.height !== maxHeight) { innerWrapper.style.height = maxHeight + 'px'; }
        }

        // get the distance from the top edge of the first slide to each slide
        // (init) => slidePositions
        function getSlidePositions() {
            slidePositions = [0];
            var attr = horizontal ? 'left' : 'top',
                first = slideItems[0].getBoundingClientRect()[attr],
                position;
            for (var i = 1; i < slideCountNew; i++) {
                position = slideItems[i].getBoundingClientRect()[attr];
                slidePositions.push(position - first);
            }
        }

        // set snapInterval (for IE10)
        function setSnapInterval() {
            outerWrapper.style.msScrollSnapPointsX = 'snapInterval(0%, ' + (100 / items) + '%)';
        }

        // update slide
        function updateSlideStatus() {
            var l = index + Math.min(slideCount, items);
            for (var i = slideCountNew; i--;) {
                var item = slideItems[i];

                // visible slides
                if (i >= index && i < l) {
                    if (hasAttr(item, 'tabindex')) {
                        setAttrs(item, { 'aria-hidden': 'false' });
                        removeAttrs(item, ['tabindex']);
                        addClass(item, slideActiveClass);
                    }
                    // hidden slides
                } else {
                    if (!hasAttr(item, 'tabindex')) {
                        setAttrs(item, {
                            'aria-hidden': 'true',
                            'tabindex': '-1'
                        });
                    }
                    if (hasClass(item, slideActiveClass)) {
                        removeClass(item, slideActiveClass);
                    }
                }
            }
        }

        // gallery: update slide position
        function updateSlidePosition() {
            if (!carousel) {
                var l = index + Math.min(slideCount, items);
                for (var i = slideCountNew; i--;) {
                    var item = slideItems[i];

                    if (i >= index && i < l) {
                        // add transitions to visible slides when adjusting their positions
                        addClass(item, 'tns-moving');

                        item.style.left = (i - index) * 100 / items + '%';
                        addClass(item, animateIn);
                        removeClass(item, animateNormal);
                    } else if (item.style.left) {
                        item.style.left = '';
                        addClass(item, animateNormal);
                        removeClass(item, animateIn);
                    }

                    // remove outlet animation
                    removeClass(item, animateOut);
                }

                // removing '.tns-moving'
                setTimeout(function () {
                    forEachNodeList(slideItems, function (el) {
                        removeClass(el, 'tns-moving');
                    });
                }, 300);
            }
        }

        // set tabindex & aria-selected on Nav
        function updateNavStatus() {
            // get current nav
            if (nav) {
                navCurrentIndex = navClicked !== -1 ? navClicked : getAbsIndex();
                navClicked = -1;

                if (navCurrentIndex !== navCurrentIndexCached) {
                    var navPrev = navItems[navCurrentIndexCached],
                        navCurrent = navItems[navCurrentIndex];

                    setAttrs(navPrev, {
                        'tabindex': '-1',
                        'aria-selected': 'false'
                    });
                    setAttrs(navCurrent, {
                        'tabindex': '0',
                        'aria-selected': 'true'
                    });
                    removeClass(navPrev, navActiveClass);
                    addClass(navCurrent, navActiveClass);

                    navCurrentIndexCached = navCurrentIndex;
                }
            }
        }

        function getLowerCaseNodeName(el) {
            return el.nodeName.toLowerCase();
        }

        function isButton(el) {
            return getLowerCaseNodeName(el) === 'button';
        }

        function isAriaDisabled(el) {
            return el.getAttribute('aria-disabled') === 'true';
        }

        function disEnableElement(isButton, el, val) {
            if (isButton) {
                el.disabled = val;
            } else {
                el.setAttribute('aria-disabled', val.toString());
            }
        }

        // set 'disabled' to true on controls when reach the edges
        function updateControlsStatus() {
            if (!controls || rewind || loop) { return; }

            var prevDisabled = (prevIsButton) ? prevButton.disabled : isAriaDisabled(prevButton),
                nextDisabled = (nextIsButton) ? nextButton.disabled : isAriaDisabled(nextButton),
                disablePrev = (index === indexMin) ? true : false,
                disableNext = (!rewind && index === indexMax) ? true : false;

            if (disablePrev && !prevDisabled) {
                disEnableElement(prevIsButton, prevButton, true);
            }
            if (!disablePrev && prevDisabled) {
                disEnableElement(prevIsButton, prevButton, false);
            }
            if (disableNext && !nextDisabled) {
                disEnableElement(nextIsButton, nextButton, true);
            }
            if (!disableNext && nextDisabled) {
                disEnableElement(nextIsButton, nextButton, false);
            }
        }

        // set duration
        function resetDuration(el, str) {
            if (TRANSITIONDURATION) { el.style[TRANSITIONDURATION] = str; }
        }

        function getFixedSliderWidth() {
            return fixedWidth ? (fixedWidth + gutter) * slideCountNew - gutter : slidePositions[slideCountNew - 1] + slideItems[slideCountNew - 1].getBoundingClientRect().width - gutter;
        }

        function getRightBoundary() {
            var result = - (getFixedSliderWidth() - vpInner);
            if (result > 0) { result = 0; }
            return result;
        }

        function getContainerTransformValue(num) {
            if (num == null) { num = index; }
            var val;
            if (horizontal && !autoWidth) {
                if (fixedWidth) {
                    val = - (fixedWidth + gutter) * num;
                } else {
                    var denominator = TRANSFORM ? slideCountNew : items;
                    val = - num * 100 / denominator;
                }
            } else {
                val = - slidePositions[num];
            }

            if (hasRightDeadZone) { val = Math.max(val, rightBoundary); }

            val += (horizontal && !autoWidth && !fixedWidth) ? '%' : 'px';
            return val;
        }

        function doContainerTransformSilent(val) {
            resetDuration(container, '0s');
            doContainerTransform(val);
            setTimeout(function () { resetDuration(container, ''); }, 10);
        }

        function doContainerTransform(val) {
            if (val == null) { val = getContainerTransformValue(); }
            container.style[transformAttr] = transformPrefix + val + transformPostfix;
        }

        function animateSlide(number, classOut, classIn, isOut) {
            var l = number + items;
            if (!loop) { l = Math.min(l, slideCountNew); }

            for (var i = number; i < l; i++) {
                var item = slideItems[i];

                // set item positions
                if (!isOut) { item.style.left = (i - index) * 100 / items + '%'; }

                if (animateDelay && TRANSITIONDELAY) {
                    item.style[TRANSITIONDELAY] = item.style[ANIMATIONDELAY] = animateDelay * (i - number) / 1000 + 's';
                }
                removeClass(item, classOut);
                addClass(item, classIn);

                if (isOut) { slideItemsOut.push(item); }
            }
        }

        // make transfer after click/drag:
        // 1. change 'transform' property for mordern browsers
        // 2. change 'left' property for legacy browsers
        var transformCore = (function () {
            return carousel ?
                function (duration, distance) {
                    if (duration == null) { duration = speed; }
                    if (TRANSITIONDURATION || !duration) {
                        // for morden browsers with non-zero duration or 
                        // zero duration for all browsers
                        doContainerTransform(distance);
                        // run fallback function manually 
                        // when duration is 0 / container is hidden
                        if (!duration || !isVisible(container)) { onTransitionEnd(); }

                    } else {
                        // for old browser with non-zero duration
                        jsTransform(container, transformAttr, transformPrefix, transformPostfix, distance, speed, onTransitionEnd);
                    }

                    if (!horizontal) { updateContentWrapperHeight(); }
                } :
                function (duration) {
                    if (duration == null) { duration = speed; }
                    slideItemsOut = [];

                    var eve = {};
                    eve[TRANSITIONEND] = eve[ANIMATIONEND] = onTransitionEnd;
                    removeEvents(slideItems[indexCached], eve);
                    addEvents(slideItems[index], eve);

                    animateSlide(indexCached, animateIn, animateOut, true);
                    animateSlide(index, animateNormal, animateIn);

                    // run fallback function manually 
                    // when transition or animation not supported / duration is 0
                    if (!TRANSITIONEND || !ANIMATIONEND || !duration || !isVisible(container)) { onTransitionEnd(); }
                };
        })();

        function render(e, sliderMoved) {
            if (updateIndexBeforeTransform) { updateIndex(); }
            // if (!carousel) { index = Math.floor(index);}

            // render when slider was moved (touch or drag) even though index may not change
            if (index !== indexCached || sliderMoved) {
                // events
                events.emit('indexChanged', info());
                events.emit('transitionStart', info());

                // pause autoplay when click or keydown from user
                if (animating && e && ['click', 'keydown'].indexOf(e.type) >= 0) { stopAutoplay(); }

                running = true;
                transformCore();
            }
        }

        /*
         * Transfer prefixed properties to the same format
         * CSS: -Webkit-Transform => webkittransform
         * JS: WebkitTransform => webkittransform
         * @param {string} str - property
         *
         */
        function strTrans(str) {
            return str.toLowerCase().replace(/-/g, '');
        }

        // AFTER TRANSFORM
        // Things need to be done after a transfer:
        // 1. check index
        // 2. add classes to visible slide
        // 3. disable controls buttons when reach the first/last slide in non-loop slider
        // 4. update nav status
        // 5. lazyload images
        // 6. update container height
        function onTransitionEnd(event) {
            // check running on gallery mode
            // make sure trantionend/animationend events run only once
            if (carousel || running) {
                events.emit('transitionEnd', info(event));

                if (!carousel && slideItemsOut.length > 0) {
                    for (var i = 0; i < slideItemsOut.length; i++) {
                        var item = slideItemsOut[i];
                        // set item positions
                        item.style.left = '';

                        if (ANIMATIONDELAY && TRANSITIONDELAY) {
                            item.style[ANIMATIONDELAY] = '';
                            item.style[TRANSITIONDELAY] = '';
                        }
                        removeClass(item, animateOut);
                        addClass(item, animateNormal);
                    }
                }

                /* update slides, nav, controls after checking ...
                 * => legacy browsers who don't support 'event' 
                 *    have to check event first, otherwise event.target will cause an error 
                 * => or 'gallery' mode: 
                 *   + event target is slide item
                 * => or 'carousel' mode: 
                 *   + event target is container, 
                 *   + event.property is the same with transform attribute
                 */
                if (!event ||
                    !carousel && event.target.parentNode === container ||
                    event.target === container && strTrans(event.propertyName) === strTrans(transformAttr)) {

                    if (!updateIndexBeforeTransform) {
                        var indexTem = index;
                        updateIndex();
                        if (index !== indexTem) {
                            events.emit('indexChanged', info());

                            doContainerTransformSilent();
                        }
                    }

                    if (autoHeight) { runAutoHeight(); }

                    if (nested === 'inner') { events.emit('innerLoaded', info()); }
                    running = false;
                    indexCached = index;
                }
            }

        }

        // # ACTIONS
        function goTo(targetIndex, e) {
            if (freeze) { return; }

            // prev slideBy
            if (targetIndex === 'prev') {
                onControlsClick(e, -1);

                // next slideBy
            } else if (targetIndex === 'next') {
                onControlsClick(e, 1);

                // go to exact slide
            } else {
                if (running) { onTransitionEnd(); }

                var absIndex = getAbsIndex(),
                    indexGap = 0;
                if (absIndex < 0) { absIndex += slideCount; }

                if (targetIndex === 'first') {
                    indexGap = - absIndex;
                } else if (targetIndex === 'last') {
                    indexGap = carousel ? slideCount - items - absIndex : slideCount - 1 - absIndex;
                } else {
                    if (typeof targetIndex !== 'number') { targetIndex = parseInt(targetIndex); }

                    if (!isNaN(targetIndex)) {
                        if (!e) {
                            targetIndex -= 1; // switch from natual index to JS index
                            if (carousel && cloneCount) { targetIndex += cloneCount; }
                        }

                        var absTargetIndex = getAbsIndex(targetIndex);
                        if (absTargetIndex < 0) { absTargetIndex += slideCount; }
                        indexGap = absTargetIndex - absIndex;
                    }
                }

                // gallery: make sure new page won't overlap with current page
                if (!carousel && indexGap && Math.abs(indexGap) < items) {
                    var factor = indexGap > 0 ? 1 : -1;
                    indexGap += (index + indexGap - slideCount) >= indexMin ? slideCount * factor : slideCount * 2 * factor * -1;
                }

                index += indexGap;

                // make sure index is in range
                if (carousel && loop) {
                    if (index < indexMin) { index += slideCount; }
                    if (index > indexMax) { index -= slideCount; }
                }

                // if index is changed, start rendering
                if (getAbsIndex(index) !== getAbsIndex(indexCached)) {
                    render(e);
                }

            }
        }

        // on controls click
        function onControlsClick(e, dir) {
            if (running) { onTransitionEnd(); }

            var passEventObject;

            if (!dir) {
                e = getEvent(e);
                var target = e.target || e.srcElement;

                while (target !== controlsContainer && [prevButton, nextButton].indexOf(target) < 0) { target = target.parentNode; }

                var targetIn = [prevButton, nextButton].indexOf(target);
                if (targetIn >= 0) {
                    passEventObject = true;
                    dir = targetIn === 0 ? -1 : 1;
                }
            }

            if (rewind) {
                if (index === indexMin && dir === -1) {
                    goTo('last', e);
                    return;
                } else if (index === indexMax && dir === 1) {
                    goTo('first', e);
                    return;
                }
            }

            if (dir) {
                index += slideBy * dir;
                if (autoWidth) { index = Math.floor(index); }
                // pass e when click control buttons or keydown
                render((passEventObject || (e && e.type === 'keydown')) ? e : null);
            }
        }

        // on nav click
        function onNavClick(e) {
            // if (!running) {
            if (running) { onTransitionEnd(); }

            e = getEvent(e);
            var target = e.target || e.srcElement,
                navIndex;

            // find the clicked nav item
            while (target !== navContainer && !hasAttr(target, 'data-nav')) { target = target.parentNode; }
            if (hasAttr(target, 'data-nav')) {
                navIndex = navClicked = [].indexOf.call(navItems, target);
                goTo(carousel ? navIndex + cloneCount : navIndex, e);
            }
            // }
        }

        // autoplay functions
        function setAutoplayTimer() {
            autoplayTimer = setInterval(function () {
                onControlsClick(null, autoplayDirection);
            }, autoplayTimeout);

            animating = true;
        }

        function stopAutoplayTimer() {
            clearInterval(autoplayTimer);
            animating = false;
        }

        function updateAutoplayButton(action, txt) {
            setAttrs(autoplayButton, { 'data-action': action });
            autoplayButton.innerHTML = autoplayHtmlStrings[0] + action + autoplayHtmlStrings[1] + txt;
        }

        function startAutoplay() {
            setAutoplayTimer();
            if (autoplayButton) { updateAutoplayButton('stop', autoplayText[1]); }
        }

        function stopAutoplay() {
            stopAutoplayTimer();
            if (autoplayButton) { updateAutoplayButton('start', autoplayText[0]); }
        }

        // programaitcally play/pause the slider
        function play() {
            if (autoplay && !animating) {
                startAutoplay();
                autoplayUserPaused = false;
            }
        }
        function pause() {
            if (animating) {
                stopAutoplay();
                autoplayUserPaused = true;
            }
        }

        function toggleAutoplay() {
            if (animating) {
                stopAutoplay();
                autoplayUserPaused = true;
            } else {
                startAutoplay();
                autoplayUserPaused = false;
            }
        }

        function onVisibilityChange() {
            if (doc.hidden) {
                if (animating) {
                    stopAutoplayTimer();
                    autoplayVisibilityPaused = true;
                }
            } else if (autoplayVisibilityPaused) {
                setAutoplayTimer();
                autoplayVisibilityPaused = false;
            }
        }

        function mouseoverPause() {
            if (animating) {
                stopAutoplayTimer();
                autoplayHoverPaused = true;
            }
        }

        function mouseoutRestart() {
            if (autoplayHoverPaused) {
                setAutoplayTimer();
                autoplayHoverPaused = false;
            }
        }

        // keydown events on document 
        function onDocumentKeydown(e) {
            e = getEvent(e);
            switch (e.keyCode) {
                case KEYS.LEFT:
                    onControlsClick(e, -1);
                    break;
                case KEYS.RIGHT:
                    onControlsClick(e, 1);
            }
        }

        // on key control
        function onControlsKeydown(e) {
            e = getEvent(e);
            var code = e.keyCode;

            switch (code) {
                case KEYS.LEFT:
                case KEYS.UP:
                case KEYS.PAGEUP:
                    if (!prevButton.disabled) {
                        onControlsClick(e, -1);
                    }
                    break;
                case KEYS.RIGHT:
                case KEYS.DOWN:
                case KEYS.PAGEDOWN:
                    if (!nextButton.disabled) {
                        onControlsClick(e, 1);
                    }
                    break;
                case KEYS.HOME:
                    goTo('first', e);
                    break;
                case KEYS.END:
                    goTo(slideCount - 1, e);
                    break;
            }
        }

        // set focus
        function setFocus(focus) {
            focus.focus();
        }

        // on key nav
        function onNavKeydown(e) {
            var curElement = doc.activeElement;
            if (!hasAttr(curElement, 'data-nav')) { return; }

            e = getEvent(e);
            var code = e.keyCode,
                navIndex = [].indexOf.call(navItems, curElement),
                len = visibleNavIndexes.length,
                current = visibleNavIndexes.indexOf(navIndex);

            if (options.navContainer) {
                len = slideCount;
                current = navIndex;
            }

            function getNavIndex(num) {
                return options.navContainer ? num : visibleNavIndexes[num];
            }

            switch (code) {
                case KEYS.LEFT:
                case KEYS.PAGEUP:
                    if (current > 0) { setFocus(navItems[getNavIndex(current - 1)]); }
                    break;

                case KEYS.UP:
                case KEYS.HOME:
                    if (current > 0) { setFocus(navItems[getNavIndex(0)]); }
                    break;

                case KEYS.RIGHT:
                case KEYS.PAGEDOWN:
                    if (current < len - 1) { setFocus(navItems[getNavIndex(current + 1)]); }
                    break;

                case KEYS.DOWN:
                case KEYS.END:
                    if (current < len - 1) { setFocus(navItems[getNavIndex(len - 1)]); }
                    break;

                // Can't use onNavClick here,
                // Because onNavClick require event.target as nav items
                case KEYS.ENTER:
                case KEYS.SPACE:
                    navClicked = navIndex;
                    goTo(navIndex + cloneCount, e);
                    break;
            }
        }

        // IE10 scroll function
        function ie10Scroll() {
            transformCore(0, container.scrollLeft);
            indexCached = index;
        }

        function getEvent(e) {
            e = e || win.event;
            return isTouchEvent(e) ? e.changedTouches[0] : e;
        }
        function getTarget(e) {
            return e.target || win.event.srcElement;
        }

        function isTouchEvent(e) {
            return e.type.indexOf('touch') >= 0;
        }

        function preventDefaultBehavior(e) {
            e.preventDefault ? e.preventDefault() : e.returnValue = false;
        }

        function onPanStart(e) {
            if (running) { onTransitionEnd(); }

            panStart = true;
            if (carousel) {
                caf(rafIndex);
                rafIndex = 0;
            }

            var $ = getEvent(e);
            events.emit(isTouchEvent(e) ? 'touchStart' : 'dragStart', info(e));

            if (!isTouchEvent(e) && ['img', 'a'].indexOf(getLowerCaseNodeName(getTarget(e))) >= 0) {
                preventDefaultBehavior(e);
            }

            lastPosition.x = initPosition.x = parseInt($.clientX);
            lastPosition.y = initPosition.y = parseInt($.clientY);
            if (carousel) {
                translateInit = parseFloat(container.style[transformAttr].replace(transformPrefix, '').replace(transformPostfix, ''));
                resetDuration(container, '0s');
            }
        }

        function onPanMove(e) {
            if (panStart) {
                var $ = getEvent(e);
                lastPosition.x = parseInt($.clientX);
                lastPosition.y = parseInt($.clientY);
            }
            if (carousel && !rafIndex) { rafIndex = raf(function () { panUpdate(e); }); }
        }

        function panUpdate(e) {
            if (!moveDirectionExpected) {
                panStart = false;
                return;
            }
            caf(rafIndex);
            if (panStart) { rafIndex = raf(function () { panUpdate(e); }); }

            if (
                moveDirectionExpected === '?' &&
                lastPosition.x !== initPosition.x &&
                lastPosition.y !== initPosition.y) {
                moveDirectionExpected = getTouchDirection(toDegree(lastPosition.y - initPosition.y, lastPosition.x - initPosition.x), swipeAngle) === options.axis;
            }

            if (moveDirectionExpected) {
                try {
                    if (e.type) { events.emit(isTouchEvent(e) ? 'touchMove' : 'dragMove', info(e)); }
                } catch (err) { }

                var x = translateInit,
                    dist = getDist(lastPosition, initPosition);
                if (!horizontal || fixedWidth || autoWidth) {
                    x += dist;
                    x += 'px';
                } else {
                    var percentageX = TRANSFORM ? dist * items * 100 / (vpInner * slideCountNew) : dist * 100 / vpInner;
                    x += percentageX;
                    x += '%';
                }

                container.style[transformAttr] = transformPrefix + x + transformPostfix;
            }
        }

        function onPanEnd(e) {
            if (swipeAngle) { moveDirectionExpected = '?'; } // reset

            if (panStart) {
                if (carousel) {
                    caf(rafIndex);
                    resetDuration(container, '');
                }
                panStart = false;

                var $ = getEvent(e);
                lastPosition.x = parseInt($.clientX);
                lastPosition.y = parseInt($.clientY);
                var dist = getDist(lastPosition, initPosition);

                // initPosition = {x:0, y:0}; // reset positions
                // lastPosition = {x:0, y:0}; // reset positions

                if (Math.abs(dist) >= 5) {
                    // drag vs click
                    if (!isTouchEvent(e)) {

                        // prevent "click"
                        var target = getTarget(e);
                        addEvents(target, {
                            'click': function preventClick(e) {
                                preventDefaultBehavior(e);
                                removeEvents(target, { 'click': preventClick });
                            }
                        });
                    }

                    if (carousel) {
                        rafIndex = raf(function () {
                            if (horizontal && !autoWidth) {
                                var indexMoved = - dist * items / vpInner;
                                indexMoved = dist > 0 ? Math.floor(indexMoved) : Math.ceil(indexMoved);
                                index += indexMoved;
                            } else {
                                var moved = - (translateInit + dist);
                                if (moved <= 0) {
                                    index = indexMin;
                                } else if (moved >= slidePositions[slidePositions.length - 1]) {
                                    index = indexMax;
                                } else {
                                    var i = 0;
                                    while (i < slideCountNew && moved >= slidePositions[i]) {
                                        index = i;
                                        if (moved > slidePositions[i] && dist < 0) { index += 1; }
                                        i++;
                                    }
                                }
                            }

                            render(e, dist);
                            events.emit(isTouchEvent(e) ? 'touchEnd' : 'dragEnd', info(e));
                        });
                    } else {
                        if (
                            moveDirectionExpected === '?' &&
                            lastPosition.x !== initPosition.x &&
                            lastPosition.y !== initPosition.y) {
                            moveDirectionExpected = getTouchDirection(toDegree(lastPosition.y - initPosition.y, lastPosition.x - initPosition.x), swipeAngle) === options.axis;
                        }

                        if (moveDirectionExpected) {
                            onControlsClick(e, dist > 0 ? -1 : 1);
                        }
                    }
                }

            }
        }

        // === RESIZE FUNCTIONS === //
        // (slidePositions, index, items) => vertical_conentWrapper.height
        function updateContentWrapperHeight() {
            innerWrapper.style.height = slidePositions[index + items] - slidePositions[index] + 'px';
        }

        /*
         * get nav item indexes per items
         * add 1 more if the nav items cann't cover all slides
         * [0, 1, 2, 3, 4] / 3 => [0, 3]
         */
        function getVisibleNavIndex() {
            // reset visibleNavIndexes
            visibleNavIndexes = [];

            var absIndexMin = getAbsIndex() % items;
            while (absIndexMin < slideCount) {
                if (carousel && !loop && absIndexMin + items > slideCount) { absIndexMin = slideCount - items; }
                visibleNavIndexes.push(absIndexMin);
                absIndexMin += items;
            }

            // nav count * items < slide count means
            // some slides can not be displayed only by nav clicking
            if (loop && visibleNavIndexes.length * items < slideCount ||
                !loop && visibleNavIndexes[0] > 0) {
                visibleNavIndexes.unshift(0);
            }
        }

        /*
         * 1. update visible nav items list
         * 2. add "hidden" attributes to previous visible nav items
         * 3. remove "hidden" attrubutes to new visible nav items
         */
        function updateNavVisibility() {
            if (!nav || navAsThumbnails) { return; }
            getVisibleNavIndex();

            if (visibleNavIndexes !== visibleNavIndexesCached) {
                forEachNodeList(navItems, function (el, i) {
                    if (visibleNavIndexes.indexOf(i) < 0) {
                        hideElement(el);
                    } else {
                        showElement(el);
                    }
                });

                // cache visible nav indexes
                visibleNavIndexesCached = visibleNavIndexes;
            }
        }

        function info(e) {
            return {
                container: container,
                slideItems: slideItems,
                navContainer: navContainer,
                navItems: navItems,
                controlsContainer: controlsContainer,
                hasControls: hasControls,
                prevButton: prevButton,
                nextButton: nextButton,
                items: items,
                slideBy: slideBy,
                cloneCount: cloneCount,
                slideCount: slideCount,
                slideCountNew: slideCountNew,
                index: index,
                indexCached: indexCached,
                navCurrentIndex: navCurrentIndex,
                navCurrentIndexCached: navCurrentIndexCached,
                visibleNavIndexes: visibleNavIndexes,
                visibleNavIndexesCached: visibleNavIndexesCached,
                sheet: sheet,
                event: e || {},
            };
        }

        return {
            getInfo: info,
            events: events,
            goTo: goTo,
            play: play,
            pause: pause,
            isOn: isOn,
            updateSliderHeight: updateInnerWrapperHeight,
            rebuild: function () {
                return tns(options);
            },

            destroy: function () {
                // remove win event listeners
                removeEvents(win, { 'resize': onResize });

                // remove arrowKeys eventlistener
                removeEvents(doc, docmentKeydownEvent);

                // sheet
                sheet.disabled = true;

                // cloned items
                if (loop) {
                    for (var j = cloneCount; j--;) {
                        if (carousel) { slideItems[0].remove(); }
                        slideItems[slideItems.length - 1].remove();
                    }
                }

                // Slide Items
                var slideClasses = ['tns-item', slideActiveClass];
                if (!carousel) { slideClasses = slideClasses.concat('tns-normal', animateIn); }

                for (var i = slideCount; i--;) {
                    var slide = slideItems[i];
                    if (slide.id.indexOf(slideId + '-item') >= 0) { slide.id = ''; }

                    slideClasses.forEach(function (cl) { removeClass(slide, cl); });
                }
                removeAttrs(slideItems, ['style', 'aria-hidden', 'tabindex']);
                slideItems = slideId = slideCount = slideCountNew = cloneCount = null;

                // controls
                if (controls) {
                    removeEvents(controlsContainer, controlsEvents);
                    if (options.controlsContainer) {
                        removeAttrs(controlsContainer, ['aria-label', 'tabindex']);
                        removeAttrs(controlsContainer.children, ['aria-controls', 'aria-disabled', 'tabindex']);
                    }
                    controlsContainer = prevButton = nextButton = null;
                }

                // nav
                if (nav) {
                    removeEvents(navContainer, navEvents);
                    if (options.navContainer) {
                        removeAttrs(navContainer, ['aria-label']);
                        removeAttrs(navItems, ['aria-selected', 'aria-controls', 'tabindex']);
                    }
                    navContainer = navItems = null;
                }

                // auto
                if (autoplay) {
                    clearInterval(autoplayTimer);
                    if (autoplayButton) {
                        removeEvents(autoplayButton, { 'click': toggleAutoplay });
                    }
                    removeEvents(container, hoverEvents);
                    removeEvents(container, visibilityEvent);
                    if (options.autoplayButton) {
                        removeAttrs(autoplayButton, ['data-action']);
                    }
                }

                // container
                container.id = containerIdCached || '';
                container.className = container.className.replace(classContainer, '');
                removeElementStyles(container);
                if (carousel && TRANSITIONEND) {
                    var eve = {};
                    eve[TRANSITIONEND] = onTransitionEnd;
                    removeEvents(container, eve);
                }
                removeEvents(container, touchEvents);
                removeEvents(container, dragEvents);

                // outerWrapper
                containerParent.insertBefore(container, outerWrapper);
                outerWrapper.remove();
                outerWrapper = innerWrapper = container =
                    index = indexCached = items = slideBy = navCurrentIndex = navCurrentIndexCached = hasControls = visibleNavIndexes = visibleNavIndexesCached =
                    this.getInfo = this.events = this.goTo = this.play = this.pause = this.destroy = null;
                this.isOn = isOn = false;
            }
        };
    };

    return tns;
})();