﻿(function () {

    var sliders = document.querySelectorAll('.deal-carousel');

    // Store the window width
    var windowWidth = window.innerWidth

    for (var i = 0; i < sliders.length; i++) {
        var glide = new Glide(sliders[i], {
            peek: 0,
            perView: 3,
            type: "slider",
            gap: 0,
            bound: true,
            rewind: false,
            perSwipe: '|',

            classes: {
                swipeable: 'g-swipeable',
                dragging: 'g-dragging',
                direction: {
                    ltr: 'g-ltr',
                },
                type: {
                    slider: 'g-slider',
                },
                slide: {
                    active: 'g-active'
                },
                arrow: {
                    disabled: 'g-arrow-disabled'
                }
            },

            breakpoints: {
                1199: {
                    perView: 3,
                },
                991: {
                    perView: 2,
                    peek: { before: 0, after: 75 },
                },
                575: {
                    perView: 1,
                    peek: { before: 0, after: 50 },
                }
            }
        });

        window.addEventListener("resize", function () {
            // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
            if (window.innerWidth != windowWidth) {
                // Update the window width for next time
                glide.go('<<');
                windowWidth = window.innerWidth
            }
        })

        glide.mount();
    }
})();