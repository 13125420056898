import Modal from "../modal.js";

const CookieService = {
    setCookie(cName, cValue, expDays) {
         /* let expires = '';*/
        const date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/; samesite=strict";
    },

    getCookie(name) {
        const cookies = document.cookie.split(';');

        for (const cookie of cookies) {
            if (cookie.indexOf(name + '=') > -1) {
                return cookie.split('=')[1];
            }
        }

        return null;
    }
};

const mouseEvent = e => {
    const shouldShowExitIntent =
        !e.toElement &&
        !e.relatedTarget &&
        e.clientY < 100;

    if (shouldShowExitIntent) {
        document.removeEventListener('mouseout', mouseEvent);
        Modal.show(document.querySelector("#newsletter-exit-modal"));
        CookieService.setCookie('leasing.com.newsletter', true, 7);
    }
};

if (!CookieService.getCookie('leasing.com.newsletter')) {
    setTimeout(() => {
        document.addEventListener('mouseout', mouseEvent);
    }, 30000);
}
