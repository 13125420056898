document.addEventListener('DOMContentLoaded', () => {
    var chatbutton = document.getElementById("chat-widget");
    if (typeof (chatbutton) != 'undefined' && chatbutton != null) {

        window.addEventListener("scroll", chatDisplay);

        function chatDisplay() {
            var midpoint = (document.body.scrollHeight / 2) - (document.body.clientHeight)
            var midpointwebkit = (document.documentElement.scrollHeight / 2) - (document.documentElement.clientHeight)

            if (document.body.scrollTop > midpoint || document.documentElement.scrollTop > midpointwebkit) {
                chatbutton.style.display = "block";
            } else {
                chatbutton.style.display = "none";
            }
        }
    }
});