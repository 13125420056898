﻿import { on } from "./helpers/events/on.js";

(function () {
    window.onpageshow = onPageLoadOrBackButton;

    function onPageLoadOrBackButton() {
        const manList3 = document.getElementById("manList3");
        if (manList3 && manList3.value) {
            refreshRanges(3);
        }
    }

    on("change", "#manList2,#manList3", function (e) {
        const i = e.target.id.slice(-1);
        refreshRanges(i);
    });
    on("click", "#manList2,#manList3", function () {
        sessionStorage.removeItem('rangeselected');
    });

    on("change", "#ranList2,#ranList3", function (e) {
        const i = e.target.id.slice(-1);
        refreshPriceRanges(i);
    });

    on("click", "#search-button3", function () {
        const ran = document.getElementById("ranList3");
        sessionStorage.setItem('rangeselected', ran.selectedIndex);
    });

    on('change', "#PriceRangesForMinBudgetDropdown2,#PriceRangesForMaxBudgetDropdown2,#PriceRangesForMinBudgetDropdown3,#PriceRangesForMaxBudgetDropdown3", function (e) {
        const i = e.target.id.slice(-1);
        var pricesJson = document.getElementById("PriceRangeDataJson" + i).value;
        var prices = JSON.parse(pricesJson);
        populatePriceRange(prices, i);
    })

    function getPriceRangesCallback(data, i) {
        document.getElementById("PriceRangeDataJson" + i).value = JSON.stringify(data);
        populatePriceRange(data, i);
    }

    function refreshRanges(i) {
        var man = document.getElementById("manList" + i);
        var selectedManufacturer = man.options[man.selectedIndex] ? man.options[man.selectedIndex].text : '';

        var ran = document.getElementById("ranList" + i);
        var selectedRange = ran.options[ran.selectedIndex] ? ran.options[ran.selectedIndex].text : '';

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                if (xmlhttp.status === 200) {
                    var modelsHtml = JSON.parse(xmlhttp.responseText);
                    populateRangeDropdown(modelsHtml, i);
                    refreshPriceRanges(i);
                }
            }
        };
        xmlhttp.open("POST", "/Service/GetAvailableRangesHome", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.setRequestHeader('x-csrf-token', document.querySelector("[name=__RequestVerificationToken]").value);
        xmlhttp.send("manufacturer=" + selectedManufacturer + "&financeType=Personal&selectedRange=" + selectedRange);
    }

    function refreshPriceRanges(i) {
        var man = document.getElementById("manList" + i);
        var selectedManufacturer = man.options[man.selectedIndex] ? man.options[man.selectedIndex].text : '';

        var ran = document.getElementById("ranList" + i);
        var selectedRange = ran.options[ran.selectedIndex] ? ran.options[ran.selectedIndex].text : '';

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                if (xmlhttp.status === 200) {
                    var prices = JSON.parse(xmlhttp.responseText);
                    getPriceRangesCallback(prices, i);
                }
            }
        };
        xmlhttp.open("POST", "/Service/GetPriceRanges", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.setRequestHeader('x-csrf-token', document.querySelector("[name=__RequestVerificationToken]").value);
        xmlhttp.send("vehicleType=Both&manufacturer=" + selectedManufacturer + "&financeType=Personal&range=" + selectedRange);
    }

    function populateRangeDropdown(modelsHtml, i) {
        var ran = document.getElementById("ranList" + i);
        ran.innerHTML = modelsHtml;
        var selectedIndex = parseInt(sessionStorage.getItem('rangeselected'));

        if (selectedIndex === null || selectedIndex === undefined || selectedIndex >= ran.options.length) {
            selectedIndex = 0;
        }

        ran.selectedIndex = selectedIndex;
    }

    function populatePriceRange(prices, i) {
        var priceTo = document.getElementById("PriceRangesForMaxBudgetDropdown" + i),
            priceFrom = document.getElementById("PriceRangesForMinBudgetDropdown" + i),
            selectedMinPrice = priceFrom.options[priceFrom.selectedIndex].value,
            selectedMaxPrice = priceTo.options[priceTo.selectedIndex].value,
            htmlDropDownFrom = "",
            htmlDropDownTo = "",
            pricesCount = prices.length,
            minPrice = parseInt(selectedMinPrice, 10),
            maxPrice = parseInt(selectedMaxPrice, 10);

        var minPriceOption = option(0, minPrice, "£ min");
        var maxPriceOption = option(0, maxPrice, "£ max");

        htmlDropDownFrom += minPriceOption;
        htmlDropDownTo += maxPriceOption;

        for (var i = 0; i < pricesCount; i++) {
            var value = prices[i];
            var text = "£" + value.toLocaleString()

            if (minPrice === 0 || value > minPrice) {
                htmlDropDownTo += option(value, maxPrice, text);
            }
            if (maxPrice === 0 || value < maxPrice) {
                htmlDropDownFrom += option(value, minPrice, text);
            }
        }

        priceTo.innerHTML = htmlDropDownTo;
        priceFrom.innerHTML = htmlDropDownFrom;
    }

    function option(value, selectedValue, text) {
        return "<option " + (value === selectedValue ? "selected" : "") + " value=\"" + value + "\">" + text + "</option>";
    }

    window["SearchDeals2"] = function (gaEventCategory, i) {
        var man = document.getElementById("manList" + i),
            ran = document.getElementById("ranList" + i),
            priceTo = document.getElementById("PriceRangesForMaxBudgetDropdown" + i),
            priceFrom = document.getElementById("PriceRangesForMinBudgetDropdown" + i),
            financeType = "Personal",
            rangeValue = ran.value,
            manufacturerValue = man.value,
            rangeText = ran.options[ran.selectedIndex].text,
            manufacturerText = man.options[man.selectedIndex].text,
            manufacturerPath = manufacturerValue !== "" ? manufacturerValue + "/" : "",
            rangePath = rangeValue !== "" ? rangeValue + "/" : "",
            priceFromValue = priceFrom.options[priceFrom.selectedIndex].value,
            priceToValue = priceTo.options[priceTo.selectedIndex].value;

        var vehicleType;
        if (rangeValue !== "") {
            vehicleType = ran.options[ran.selectedIndex].getAttribute("data-type").replace("both", "car");
        } else {
            vehicleType = man.options[man.selectedIndex].getAttribute("data-type").replace("both", "car");
        }

        redirectToSearchPage(financeType, priceFromValue, priceToValue, vehicleType, manufacturerPath, rangePath, manufacturerValue, rangeValue, manufacturerText, rangeText);
    }

    function redirectToSearchPage(financeType, priceFrom, priceTo, vehicleType, manufacturerPath, rangePath, manufacturerValue, rangeValue, manufacturerText, rangeText) {
        var searchResultUrl = '/' + vehicleType + '-leasing/' + manufacturerPath + rangePath;

        var searchParams = new URLSearchParams();
        searchParams.append("finance", financeType.toLowerCase());
        if (priceFrom && priceFrom != 0) {
            searchParams.append("price_from", priceFrom);
        }
        if (priceTo && priceTo != 0) {
            searchParams.append("price_to", priceTo);
        }
        var querystring = searchParams.toString();
        if (querystring) {
            searchResultUrl = searchResultUrl + "?" + querystring;
        }

        window.location.href = searchResultUrl;
    }

})();