﻿

(function () {
    var sliders = document.querySelectorAll('.testimonials-carousel-inner');
    // Store the window width
    var windowWidth = window.innerWidth

    for (var i = 0; i < sliders.length; i++) {
        var glide = new Glide(sliders[i], {
            perView: 3,
            focusAt: "center",
              
            type: "carousel",
            autoplay: 6000,


            classes: {
                swipeable: 'g-swipeable',
                dragging: 'g-dragging',
                direction: {
                    ltr: 'g-ltr',
                },
                type: {
                    slider: 'g-slider',
                },
                slide: {
                    active: 'g-active'
                },
                arrow: {
                    disabled: 'g-arrow-disabled'
                }
            },

            breakpoints: {
                992: {
                    perView: 2,
                },

                575: {
                    perView: 1,
                    peek: 0
                }
            }
        });

        glide.mount();

    }
})();

